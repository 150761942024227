﻿.k-grid {
    border: 5px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 2px 14px rgba(0, 0, 0, 0.12);

    .k-grid-header {
        padding-right: 0 !important;

        .k-link {
            transition: background-color 0.1s ease-in-out;

            &:hover,
            &:focus {
                background-color: $grid-alt-bg;
            }
        }

        .k-header {
            vertical-align: middle;
        }
    }

    &-content {
        overflow: visible;
    }
    /*a:hover,
    a:focus {
        text-decoration: underline;
    }*/
    & tr {
        transition: background-color 0.1s ease-in-out;
    }

    & tr td {
        border-color: transparent;
    }

    & tr:not(.k-state-selected):hover {
        background: none;
        color: inherit;
    }

    & tr.k-alt:not(.k-state-selected):hover {
        background: $grid-alt-bg;
    }
}

.k-grouping-header
{
    display: none !important;
}

.k-grid-header {
    border-bottom-style: solid;
    border-bottom-color: $grid-border;
}
// The bootstrap sr-only class isn't specific enough to override kendo's position relative default property.
.sr-only.k-header {
    position: absolute;
}

.hidden-header {
    & .k-grid-header {
        @include sr-only();
    }
}
