@include exports("autocomplete/theme") {

    .k-autocomplete {
        @include appearance( input );
        transition: $transition;

        &.k-state-hover { @include appearance( hovered-input ); }
        &.k-state-focused { @include appearance( focused-input ); }
    }

}