@include exports("switch/theme") {

    $switch-border-radius: ($switch-size / 2) !default;

    // Theme
    .k-switch,
    .km-switch {
        @include border-radius( $switch-border-radius );
        overflow: hidden;
    }

    // Switch wrapper
    .k-switch-wrapper,
    .km-switch-wrapper {
        @include appearance( button );
        @include border-radius( $switch-border-radius );
    }
    .k-switch-background,
    .km-switch-background {
        @include appearance( primary-button );
    }

    // Switch container
    .k-switch-container,
    .km-switch-container {
        @include border-radius( $switch-border-radius );
        @include appearance( panel );
    }
    .k-switch-handle,
    .km-switch-handle {
        @include appearance( button );
        @include border-radius( 50% );
        color: $button-border;
        box-shadow: $button-shadow;
    }
    .k-switch-label-on,
    .km-switch-label-on {
        color: $primary-button-bg;
    }

    .k-switch-on .k-switch-handle,
    .km-switch-on .km-switch-handle {
        @include appearance( primary-button );
    }

}