@include exports("datetime/theme") {
    $time-list-highlight-color: $border-color !default;
    $time-list-focused-bg: rgba(0, 0, 0, .02);
    $time-list-title-focus: #000 !default;

    // TODO: move to theme default. Issue: https://github.com/telerik/kendo-theme-default/issues/796
    $time-header-padding: $padding-y $padding-x;

    // Shared
    .k-datepicker,
    .k-timepicker,
    .k-datetimepicker {

        // Normal state
        .k-picker-wrap {
            @include appearance( input );
            transition: $transition;
        }
        .k-select {
            @include appearance( button );
            transition: $transition;
        }

        // Hovered state
        .k-state-hover {
            @include appearance( hovered-input );

            .k-select {
                @include appearance( hovered-button );
            }
        }

        // Focused state
        .k-state-focused {
            @include appearance( focused-input );
        }

        // Selected state
        .k-state-active {
            @include appearance( focused-input );

            .k-select {
                @include appearance( pressed-button );
            }
        }
    }

    .k-dateinput.k-state-invalid {
        .k-textbox {
            color: $error;
            border-color: $error;
        }

        .k-i-warning {
            color: $error;
        }
    }

    // Datepicker
    .k-datepicker,
    .k-timepicker {
        .k-i-warning {
            color: $error;
            right: $spacer * 2.5;
        }

        .k-state-invalid {
            transition: none;
            color: $error;
            border-color: $error;
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                left: $spacer * 2.5;
            }
        }
    }

    // Timepicker
    .k-timepicker {}

    .k-timeselector {
        border-color: inherit;
    }

    // Timepicker header
    .k-time-header {
        padding: $time-header-padding;

        .k-title {
            font-weight: bold;
        }

        .k-time-now {
            color: $accent;
            cursor: pointer;

            &:hover,
            &:focus {
                color: $link-hover-text;
            }
        }
    }

    // Timepicker content
    .k-time-list-wrapper {
        background-color: $popup-bg;

        .k-title {
            z-index: 12;
            opacity: $disabled-opacity;
            font-size: $font-size-xs;
            color: $header-text;
        }

        &.k-state-focused {
            .k-title {
                color: $time-list-title-focus;
                opacity: 1;
            }

            &::before,
            &::after {
                background-color: $time-list-focused-bg;
                border-width: 0;
            }
        }
    }

    .k-time-list {
        &::before,
        &::after {
            $shadow-size: 3em;
            box-shadow: 0 0 $shadow-size ($shadow-size) $popup-bg;
        }

        .k-item:hover {
            color: $accent;
        }
    }

    .k-time-container {
        background: transparent;
    }

    .k-time-highlight {
        background-color: $bg-color;
        border-color: $time-list-highlight-color;
    }

    // Datetimepicker
    .k-datetimepicker {

        .k-select {
            width: auto;
        }
        .k-link {
            padding: 0;
            width: add-three( $button-border-width, $icon-size, $button-padding-y * 2 );
        }

        .k-i-warning {
            color: $error;
            right: calc( #{ button-inner-size( 2 ) } + #{ $input-padding-x-sm });
        }

        .k-state-invalid {
            transition: none;
            color: $error;
            border-color: $error;
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                left: calc( #{ button-inner-size( 2 ) } + #{ $input-padding-x-sm });
            }
        }
    }

    // Dateinput
    .k-dateinput {

        // Normal state
        .k-dateinput-wrap {
            @include appearance( input );
        }
        .k-select {
            @include appearance( button );

            &:hover {
                @include appearance( hovered-button );
            }
        }

        // Focused state
        .k-state-focused {
            @include appearance( focused-input );
        }
    }
}
