@include exports("maskedtextbox/layout") {
    .k-maskedtextbox {
        .k-i-warning {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: $padding-x;
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                right: auto;
                left: $padding-x;
            }
        }

        &.k-state-invalid {
            .k-i-warning {
                display: inline-block;
            }
        }
    }
}
