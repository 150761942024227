@include exports("scheduler/theme") {

    // Appearance
    .k-scheduler {}


    // Header and footer
    .k-scheduler-toolbar,
    .k-scheduler-footer {
        @include appearance( header );
    }


    //Scheduler navigation
    .k-scheduler-navigation {

        .k-nav-today,
        .k-nav-prev,
        .k-nav-next {
            @include appearance( button );

            &.k-state-hover { @include appearance( hovered-button ); }
        }
        .k-nav-today { @include border-radius( $border-radius 0 0 $border-radius ); }
        .k-nav-next { @include border-radius( 0 $border-radius $border-radius 0 ); }
    }


    // View switcher
    .k-scheduler-views {

        li {
            @include appearance( button );

            &.k-state-hover { @include appearance( hovered-button ); }
            &.k-state-selected { @include appearance( pressed-button ); }
        }
        li:first-child { @include border-radius( $border-radius ); }
        li:first-child + li { @include border-radius( $border-radius 0 0 $border-radius ); }
        li:last-child { @include border-radius( 0 $border-radius $border-radius 0 ); }


        &.k-state-expanded {
            li {
                margin: 0;
                border-radius: 0;
            }
            li + li { margin-top: -1px; }
            li:first-child { @include border-radius( $border-radius $border-radius 0 0 ); }
            li:first-child + li { border-radius: 0; }
            li:last-child { @include border-radius( 0 0 $border-radius $border-radius ); }
        }
    }


    // Scheduler footer
    .k-scheduler-footer {

        li {
            @include appearance( button );
            @include border-radius( $border-radius );

            &.k-state-hover { @include appearance( hovered-button ); }
        }
    }


    // Scheduler content
    .k-scheduler-content {}
    .k-nonwork-hour {
        background-color: mix( $panel-text, $panel-bg, ($color-level-step / 2) );
    }


    // Appointments
    .k-event {
        @include appearance( selected-node );
        @include border-radius( $border-radius );
    }
    .k-event-inverse {
        color: $panel-bg;
    }


    // Drag hint
    .k-event-drag-hint {}


    // Resizing
    .k-scheduler-marquee {

    }
    .k-scheduler-marquee::before,
    .k-scheduler-marquee::after {
        border-color: $primary-button-border;
    }

}




@include exports("scheduler-edit-form/theme") {

    .k-popup-edit-form {}

}
