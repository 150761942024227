@include exports( "common/lines" ) {

    // Lines
    .k-line {
        background-color: currentColor;
        border-color: currentColor;
    }
    .k-line-h,
    .k-line-v {
        position: absolute;
    }
    .k-line-h { height: 2px; }
    .k-line-v { width: 2px; }

}
