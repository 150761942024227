﻿.app {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 1fr;
    grid-template-rows: 3rem 1fr 1fr min-content;
    grid-template-areas: "header header header header" "nav main main main" "nav main main main" "nav main main main";
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
        grid-template-areas: "header header header header" "main main main main" "main main main main" "main main main main";
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    & .sidebar_user_menu{
        display: none;
    }
}

.app__noheader {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 1fr;
    grid-template-rows: 3rem 1fr 1fr min-content;
    grid-template-areas: "nav main main main" "nav main main main" "nav main main main" "nav main main main";
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
        grid-template-areas: "header header header header" "main main main main" "main main main main" "footer footer footer footer";
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    & .sidebar_user_menu {
        display: flex;
    }
}

.app__main__noheader {
    grid-area: main;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.app__footer {
    grid-area: footer;
}

.app__sidenav {
    grid-area: nav;
    min-width: 14rem;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.app__header {
    grid-area: header;
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.app__main {
    grid-area: main;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
}

.orgTree__div{
    overflow: scroll;
}

.topicTree__div {
    height: 35vh !important;
    overflow: auto;
}

.alert_modal_div{
    z-index: 9999 !important;
}


#home_page {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}

#home_page {
    font-family: Nunito, sans-serif;
    background-color: white;
}

#organizationsDiv {
    font-family: Nunito, sans-serif;
    background-color: white;
}

#home_page.box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
}
.container-fluid-white {
    width: 100%;
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
    background-color: white;
}