@include exports("card/theme") {

    // Card
    .k-card {
        @include fill( $card-text, $card-bg, $card-border );
    }


    // Header
    .k-card-header {
        @include fill( $card-header-text, $card-header-bg, $card-header-border );
    }


    // Card actions
    .k-card-actions {
        border-color: $card-border;
    }


    // Theme variants
    .k-card.k-state-primary { @extend .k-state-primary !optional; }
    .k-card.k-state-info { @extend .k-state-info !optional; }
    .k-card.k-state-success { @extend .k-state-success !optional; }
    .k-card.k-state-warning { @extend .k-state-warning !optional; }
    .k-card.k-state-error { @extend .k-state-error !optional; }


    .k-card-wrap {
        &.k-state-focused,
        &.k-state-selected {
            > .k-card {
                box-shadow: $card-box-focused-shadow;
            }
        }
    }

}
