﻿
.usersList {
    width: 100% !important;

    &.noshadow {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: none;
        border-radius: 0px;
    }

    &.border {
        box-shadow: inset 0px -1px 0px #E2E2E2, inset 1px 0px 0px #E2E2E2;
        border-radius: 0px !important;
    }

    td {
        border-top: 1px solid #E2E2E2 !important;
        border-right: 1px solid #E2E2E2 !important;
        background-color: white;
        padding: 0px;
        height: 30px;
    }

    th {
        border-right: 1px solid #E2E2E2 !important;
    }

    tr {
        padding: 0px;
        height: 30px;
        border-bottom: 1px solid #E2E2E2 !important;
    }
}


div#organizationInfoBodyDiv fieldset div.row {
    border-bottom: 0.5px solid #dddada !important;
    padding: 0.5rem !important;
}

.removeBtn {
    /* Master */
    /* Auto Layout */
    cursor: pointer;
    align-self: center;
    flex-direction: column;
    align-items: center;
    padding: 5px 5px;
    position: static;
    width: 85px;
    left: calc(50% - 85px/2);
    top: 0%;
    bottom: 0%;
    /* Neutral/95 */

    background: #F1F1F1;
    border-radius: 8px;
    border: 0px !important;
    /* Inside Auto Layout */
    order: 0;
    flex-grow: 0;

    outline: none;
}


div.usersList td{
    padding: 6px !important;
}

.indicator-bar {
    /* Auto Layout */
    
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7.5px 32px 7.5px 16px;
    position: absolute;
    width: 100%;
    height: 59px;
    top: 47px;
    background: #FFFFFF;
    /* Error/50 */


    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    /* Border/Bottom */


    box-shadow: inset 0px -1px 0px #E2E2E2;
}
@media screen and (min-width: 768px) {

    .indicator-bar {
        margin-left: -1rem;
    }
}


    .indicator-bar span {
        position: static;
        left: 20.74%;
        right: 5.93%;
        top: 27.27%;
        bottom: 27.27%;
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */


        display: flex;
        align-items: center;
        padding-right: 30px;
    }


th {
    padding: 1px !important;
}


.users-information {
    width: 100%;
    height: fit-content;
}


.col-6 p {
    margin-bottom: 0px;
}


#listOfUsers {
    overflow-y: scroll;
    padding-left: 3px;
    height: 320px;
}



.card-content {
    /* Auto Layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0px;
    position: relative;
    width: 100%;
    left: 11px;
    top: 70px;
    overflow: hidden;
    /* Backgrounds/100 */

    border: 1px solid #e2e2e2;
    background: #FFFFFF;
    /* Error/50 */

    box-sizing: border-box;
    /* Border/All */

    box-shadow: inset 0px 1px 0px #E2E2E2, inset -1px 0px 0px #E2E2E2, inset 0px -1px 0px #E2E2E2, inset 1px 0px 0px #E2E2E2;
    border-radius: 8px;
}


.main-container {
    width: 100% !important;
    display: flex;
}

.blue-font {
    color: blue !important;
}

.select-user-row {
    display: contents;
}


.filter-text-box {
    /* Auto Layout */


    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px 0px 8px;
    position: static;
    width: 468px;
    height: 32px;
    left: 16px;
    top: 16px;
    /* Backgrounds/95 */


    background: #F1F1F1;
    border-radius: 8px;
    /* Inside Auto Layout */


    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px;
}


    .filter-text-box input {
        width: 85%;
        background: #F1F1F1;
        border: none;
    }


        .filter-text-box input:focus {
            outline: none;
        }
