﻿// Custom dropdown variant for bootstrap 4's dropdown. Removes button outline and caret.
.dropdown--clear {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
        background-color: inherit;
        border-color: darken($gray-100, 10%);
    }

    &:active {
        border-color: darken($gray-100, 12.5%);
    }

    &:after {
        content: none;
    }
}
