@include exports("multiselect/theme") {

    // Appearance
    .k-multiselect-wrap {
        @include appearance( input );
        transition: $transition;

        &:hover,
        .k-state-hover > & {
            @include appearance( hovered-input );
        }

        .k-state-focused & {
            @include appearance( focused-input );
        }

        .k-button {
            min-height: calc(#{$form-line-height}*1em + #{$button-padding-y-sm} + 2px + 2px);
            padding: add-two(($button-padding-y-sm / 2), 1px) ($button-padding-x / 2);
        }
    }
}
