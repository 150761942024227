﻿#orgTreeList .k-grid-header {
    display: none;
}

#orgTreeList .k-grid-content {
    max-height: 85vh;
    overflow: auto;
}

#orgTreeList tr td.k-command-cell {
    text-align: right;    
}

#orgTreeList .k-button {
    background-color: transparent;
    color: #000;
    border: none;
}

#orgTreeList .k-alt {
    background-color: transparent;
}

#orgTreeList .k-textbox {
    border-color: black;
}

#orgTreeList tr[role=row],
#orgTreeList tr[role=row]:hover {
    background-color: transparent;
}

// Overriding organizations page to new view
div.organizationView {
    div.editOrganization {
        height: max-content;

        .k-dropdown-wrap {
            color: $input-color;
            background-color: $input-bg;
            border: none;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        }

        .form-group label {
            white-space: nowrap;
        }
    }

    div.childOrganization {
        height: max-content;

        &-header {
            padding: 0 3%;

            h3 {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &-content {
            box-shadow: none;
        }

        &-description {
            span {
                padding: 0 2rem;
            }
        }
    }
}

#orgTreeList table.k-selectable tr.k-state-selected {
    background-color: #ecf1f8;
}

#orgTreeList table.k-selectable tr td:nth-child(1) {
    cursor:pointer;
}

.orgnname-custom-color {
    color: #0056e6;
}

#orgTreeList table.k-selectable tr.k-state-selected td:nth-child(2) {
    border-right: 2px solid #166dff;
}

#editBlock .card-header .title {
    font-size: 17px;
    color: #000;
}
#editBlock .card-header .accicon {

}
#editBlock .card-header{
    cursor: pointer;
    border-bottom: none;
}

#editBlock .card-body{
    border-bottom: 1px solid #ddd;
}
#editBlock .card-header:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
}