@include exports("treeview/theme") {

    // Appearance
    .k-treeview {
        .k-top,
        .k-mid,
        .k-bot {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        // Link
        .k-in {
            @include border-radius( $border-radius );
            transition: $transition;
        }
        .k-in.k-state-hover {
            @include appearance( hovered-node );
        }
        .k-in.k-state-selected {
            @include appearance( selected-node );
        }
        .k-in.k-state-focused {
            box-shadow: 0 0 0 2px $node-border;
            z-index: 1;
        }
        .k-in.k-state-selected.k-state-focused {
            box-shadow: 0 0 0 2px rgba( $node-selected-border, .5 );
        }

    }

}
