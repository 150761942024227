@import "../modules/@progress/kendo-theme-default/scss/mixins/core/config-maps";
@import "../modules/@progress/kendo-theme-default/scss/mixins/colors";
@import "bootstrap-overrides";
@import "../modules/bootstrap/scss/functions";
@import "../modules/bootstrap/scss/variables";


// Options
$enable-rounded: $enable-rounded !default;
$enable-shadows: $enable-shadows !default;
$enable-gradients: $enable-gradients !default;
$enable-transitions: $enable-transitions !default;


// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
/// @group color-system
$accent: $primary !default;

/// The color used along with the accent color denoted by $accent.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
$accent-contrast: contrast-wcag( $accent ) !default;

/// The color for error messages and states.
/// @group color-system
$success: $success !default;

/// The color for warning messages and states.
/// @group color-system
$info: $info !default;

/// The color for success messages and states.
/// @group color-system
$warning: $warning !default;

/// The color for informational messages and states.
/// @group color-system
$error: $danger !default;

/// Set a specific jump point for requesting color jumps
/// @group color-system
$color-level-step: $theme-color-interval !default;

$rgba-transparent: rgba( 0, 0, 0, 0 );


// Contrast
/// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
/// @group color-system
$yiq-threshold: $yiq-contrasted-threshold !default;

/// Dark color for use in YIQ color contrast function.
/// @group color-system
$yiq-dark: $yiq-text-dark !default;

/// Light color for use in YIQ color contrast function.
/// @group color-system
$yiq-light: $yiq-text-light !default;


// Spacing
$spacer: $spacer !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;


// Fonts

/// Base font size across all components.
/// @group fonts
$font-size: $font-size-base !default;
$font-size-xs: .75 * $font-size-base !default;
$font-size-sm: $font-size-sm !default;
$font-size-lg: $font-size-lg !default;

/// Font family across all components.
/// @group fonts
$font-family: $font-family-base !default;

/// Font family for monospaced text. Used for styling the code.
/// @group fonts
$font-family-monospace: $font-family-monospace !default;

/// Line height used along with $font-size.
/// @group fonts
$line-height: $line-height-base !default;
$line-height-sm: $line-height-sm !default;
$line-height-lg: $line-height-lg !default;
$line-height-em: $line-height * 1em;


// Font weight
$font-weight-light: $font-weight-light !default;
$font-weight-normal: $font-weight-normal !default;
$font-weight-bold: $font-weight-bold !default;


// Headings
$h1-font-size: $h1-font-size !default;
$h2-font-size: $h2-font-size !default;
$h3-font-size: $h3-font-size !default;
$h4-font-size: $h4-font-size !default;
$h5-font-size: $h5-font-size !default;
$h6-font-size: $h6-font-size !default;

$h1-margin-bottom: $headings-margin-bottom !default;
$h2-margin-bottom: $headings-margin-bottom !default;
$h3-margin-bottom: $headings-margin-bottom !default;
$h4-margin-bottom: $headings-margin-bottom !default;
$h5-margin-bottom: $headings-margin-bottom !default;
$h6-margin-bottom: $headings-margin-bottom !default;

// Display
$display1-font-size: $display1-size !default;
$display2-font-size: $display2-size !default;
$display3-font-size: $display3-size !default;
$display4-font-size: $display4-size !default;

// Paragraph
$paragraph-margin-bottom: $paragraph-margin-bottom !default;


// Metrics
$padding-x: $spacer !default;
$padding-y: $spacer / 2 !default;
$padding-x-sm: $padding-x / 2 !default;
$padding-y-sm: $padding-y / 2 !default;
$padding-x-lg: $padding-x * 1.5 !default;
$padding-y-lg: $padding-y * 1.5 !default;

/// Border radius for all components.
$border-radius: $border-radius !default;
$border-radius-sm: $border-radius-sm !default;
$border-radius-lg: $border-radius-lg !default;

$panel-padding-x: $card-spacer-x !default;
$panel-padding-y: $card-spacer-y !default;

$popup-padding-x: 0 !default;
$popup-padding-y: $dropdown-padding-y !default;

$header-padding-x: $card-spacer-x !default;
$header-padding-y: $card-spacer-y !default;

$form-line-height: $input-btn-line-height !default;
$form-line-height-em: $form-line-height * 1em !default;

$button-padding-x: $btn-padding-x !default;
$button-padding-y: $btn-padding-y !default;
$button-padding-x-sm: $btn-padding-x-sm !default;
$button-padding-y-sm: $btn-padding-y-sm !default;
$button-calc-size: #{$form-line-height-em} + #{$button-padding-y * 2} + 2px;
$button-inner-calc-size: #{$form-line-height-em} + #{$button-padding-y * 2};

$input-border-width: $input-border-width !default;
$input-border-radius: $input-border-radius !default;
$input-padding-x: $input-padding-x !default;
$input-padding-y: $input-padding-y !default;
$input-padding-x-sm: $input-padding-x-sm !default;
$input-padding-y-sm: $input-padding-y-sm !default;
$input-calc-size: #{$form-line-height-em} + #{$input-padding-y * 2} + 2px;
$input-inner-calc-size: #{$form-line-height-em} + #{$input-padding-y * 2};

/// The horizontal padding of the container.
/// @group toolbar
$toolbar-padding-x: $table-cell-padding / 2 !default;

/// The vertical padding of the container.
/// @group toolbar
$toolbar-padding-y: $table-cell-padding / 2 !default;

$toolbar-button-padding-x: $button-padding-x !default;
$toolbar-button-padding-y: $button-padding-y !default;
$toolbar-inner-calc-size: #{$button-calc-size} + #{$toolbar-padding-y * 2} !default;

$list-item-padding-x: $dropdown-item-padding-x !default;
$list-item-padding-y: 3px !default;

$nav-item-margin: 0 !default;
$nav-item-padding-x: $padding-x !default;
$nav-item-padding-y: $padding-y !default;

$cell-padding-x: $table-cell-padding !default;
$cell-padding-y: $table-cell-padding !default;
$cell-padding: $table-cell-padding !default;
$cell-padding-sm: $table-cell-padding-sm !default;

$window-inner-padding-x: $modal-inner-padding !default;
$window-inner-padding-y: $modal-inner-padding !default;
$window-titlebar-padding-x: $modal-header-padding !default;
$window-titlebar-padding-y: $modal-header-padding !default;

$progressbar-height: $progress-height !default;

$zindex-popup: 1;
$zindex-window: 2;

$text-color: $body-color !default;
$bg-color: $body-bg !default;
$border-color: try-darken( $bg-color, 12% ) !default;

/// The background of the components' chrome area.
$base-bg: try-darken( $component-bg, 3% ) !default;
/// The text color of the components' chrome area.
$base-text: $component-color !default;
/// The border color of the components' chrome area.
$base-border: try-darken( $base-bg, 3% ) !default;
/// The gradient background of the components' chrome area.
$base-gradient: false !default;

/// The background of hovered items.
$hovered-bg: try-darken( $base-bg, 3% ) !default;
/// The text color of hovered items.
$hovered-text: $base-text !default;
/// The border color of hovered items.
$hovered-border: try-darken( $base-border, 12% ) !default;
/// The gradient background of hovered items.
$hovered-gradient: false !default;

/// The background of selected items.
$selected-bg: $accent !default;
/// The text color of selected items.
$selected-text: contrast-wcag( $selected-bg ) !default;
/// The border color of selected items.
$selected-border: try-darken( $selected-bg, 12% ) !default;
/// The gradient background of selected items.
$selected-gradient: false !default;

$transition: color .2s ease, background-color .2s ease, border-color .2s ease, box-shadow .2s ease !default;

$invalid-border: $error !default;

$gradient-transparent-to-black: rgba( black, 0), black;
$gradient-transparent-to-white: rgba( white, 0), white;
$gradient-black-to-transparent: black, rgba( black, 0);
$gradient-white-to-transparent: white, rgba( white, 0);

$gradient-rainbow: #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000;




// Component
$componet-bg: $component-bg !default;
$componet-text: $component-color !default;
$componet-border: $component-border-color !default;


// Widget
$widget-bg: $component-bg !default;
$widget-text: $component-color !default;
$widget-border: $component-border-color !default;


// Panel
$panel-text: $card-color !default;
$panel-bg: $card-bg !default;
$panel-border: $card-border-color !default;


// Popup
$popup-text: $dropdown-link-color !default;
$popup-bg: $dropdown-bg !default;
$popup-border: $dropdown-border-color !default;


// Header
$header-text: $card-cap-color !default;
$header-bg: $card-cap-bg !default;
$header-border: try-darken( $header-bg, 9 ) !default;
$header-gradient: false !default;

$header-hovered-text: $header-text !default;
$header-hovered-bg: try-darken( $header-bg, 3 ) !default;
$header-hovered-border: $header-border !default;
$header-hovered-gradient: false !default;

$header-selected-text: $selected-text !default;
$header-selected-bg: $selected-bg !default;
$header-selected-border: $selected-border !default;
$header-selected-gradient: false !default;


// Button

/// The background of the buttons.
/// @group buttons
$button-bg: $secondary !default;

/// The text color of the buttons.
/// @group buttons
$button-text: contrast-wcag( $button-bg, $gray-900 ) !default;

/// The border color of the buttons.
/// @group buttons
$button-border: $button-bg !default;

/// The background gradient of the buttons.
/// @group buttons
$button-gradient: false !default;
$button-shadow: false !default;

/// The background of hovered buttons.
/// @group buttons
$button-hovered-bg: try-darken( $button-bg, 7.5% ) !default;

/// The text color of hovered buttons.
/// @group buttons
$button-hovered-text: contrast-wcag( $button-hovered-bg, $gray-900 ) !default;

/// The border color of hovered buttons.
/// @group buttons
$button-hovered-border: try-darken( $button-border, 10% ) !default;

/// The background gradient of hovered buttons.
/// @group buttons
$button-hovered-gradient: false !default;
$button-hovered-shadow: false !default;

/// The background color of pressed buttons.
/// @group buttons
$button-pressed-bg:  try-darken( $button-bg, 10% ) !default;

/// The text color of pressed buttons.
/// @group buttons
$button-pressed-text: contrast-wcag( $button-pressed-bg, $gray-900 ) !default;

/// The border color of pressed buttons.
/// @group buttons
$button-pressed-border: try-darken( $button-border, 12.5% ) !default;

/// The background gradient of pressed buttons.
/// @group buttons
$button-pressed-gradient: false !default;
$button-pressed-shadow: false !default;

/// The shadow of focused buttons.
/// @group buttons
$button-focused-shadow: 0 0 0 3px rgba($button-border, .5) !default;


// Primary button
$primary-button-bg: $accent !default;
$primary-button-text: contrast-wcag( $primary-button-bg ) !default;
$primary-button-border: $primary-button-bg !default;
$primary-button-gradient: false !default;

$primary-button-hovered-bg: try-darken( $primary-button-bg, 7.5% ) !default;
$primary-button-hovered-text: contrast-wcag( $primary-button-hovered-bg ) !default;
$primary-button-hovered-border: try-darken( $primary-button-border, 10% ) !default;
$primary-button-hovered-gradient: false !default;

$primary-button-pressed-bg: try-darken( $primary-button-bg, 10% ) !default;
$primary-button-pressed-text: contrast-wcag( $primary-button-pressed-bg ) !default;
$primary-button-pressed-border: try-darken( $primary-button-border, 12.5% ) !default;
$primary-button-pressed-gradient: false !default;

$primary-button-focused-shadow: 0 0 0 3px rgba($primary-button-border, .5);

$flat-button-hover-opacity: .08 !default;
$flat-button-focused-opacity: false !default;
$flat-button-active-opacity: .16 !default;
$flat-button-selected-opacity: .2 !default;


// Toolbar
$toolbar-text: $header-text !default;
$toolbar-bg: $header-bg !default;
$toolbar-border: $header-border !default;
$toolbar-gradient: false !default;


// Input
$input-text: $input-color !default;
$input-bg: $input-bg !default;
$input-border: $input-border-color !default;
$input-shadow: none !default;

$input-hovered-text: $input-text !default;
$input-hovered-bg: $input-bg !default;
$input-hovered-border: $input-border !default;
$input-hovered-shadow: none !default;

$input-focused-text: $input-text !default;
$input-focused-bg: $input-bg !default;
$input-focused-border: try-lighten( $accent, 25 ) !default;
$input-focused-shadow: none !default;


// Link
$link-text: $link-color !default;
$link-hover-text: $link-hover-color !default;


// Node
$node-text: $base-text !default;
$node-bg: $base-bg !default;
$node-border: $base-border !default;
$node-gradient: false !default;

$node-hovered-text: $list-group-hover-color !default;
$node-hovered-bg: $list-group-hover-bg !default;
$node-hovered-border: $node-hovered-bg !default;
$node-hovered-gradient: false !default;

$node-selected-text: $selected-text !default;
$node-selected-bg: $selected-bg !default;
$node-selected-border: $selected-border !default;
$node-selected-gradient: false !default;


// List item
$list-item-text: $panel-text !default;
$list-item-bg: $panel-bg !default;

$list-item-hovered-text: $dropdown-link-hover-color !default;
$list-item-hovered-bg: $dropdown-link-hover-bg !default;

$list-item-selected-text: $dropdown-link-active-color !default;
$list-item-selected-bg: $dropdown-link-active-bg !default;

$list-item-focused-shadow: inset 0 0 0 3px rgba( $list-item-text, .15 );


// Tab
$tab-item-text: $link-text !default;
$tab-item-bg: transparent !default;
$tab-item-border: $nav-tabs-border-color !default;

$tab-item-hovered-text: $link-hover-text !default;
$tab-item-hovered-bg: transparent !default;
$tab-item-hovered-border: $nav-tabs-link-hover-border-color !default;

$tab-item-selected-text: $nav-tabs-link-active-color !default;
$tab-item-selected-bg: $nav-tabs-link-active-bg !default;
$tab-item-selected-border: $nav-tabs-link-active-border-color !default;


// Grid
$grid-bg: $table-bg !default;
$grid-text: $table-color !default;
$grid-border: $table-border-color !default;

$grid-alt-text: $grid-text !default;
$grid-alt-bg: rgba( $grid-alt-text, .03) !default;
$grid-alt-border: $grid-border;

$grid-hovered-text: $grid-text !default;
$grid-hovered-bg: rgba( $grid-hovered-text, .065) !default;
$grid-hovered-border: $grid-border !default;

$grid-header-bg: $header-bg !default;
$grid-header-text: $header-text !default;
$grid-header-border: $header-border !default;


//Pager
$pager-text: $pagination-color !default;
$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border-color !default;

$pager-hover-text: $pagination-hover-color !default;
$pager-hover-bg: $pagination-hover-bg !default;
$pager-hover-border: $pagination-hover-border-color !default;

$pager-selected-text: $pagination-active-color !default;
$pager-selected-bg: $pagination-active-bg !default;
$pager-selected-border: $pagination-active-border-color !default;

// Notification
$notification-info-bg: color-level($info, 10) !default;
$notification-info-text: color-level($info, -6) !default;
$notification-info-border: color-level($info, 9) !default;
$notification-success-bg: color-level($success, 10) !default;
$notification-success-text: color-level($success, -6) !default;
$notification-success-border: color-level($success, 9) !default;
$notification-warning-bg: color-level($warning, 10) !default;
$notification-warning-text: color-level($warning, -6) !default;
$notification-warning-border: color-level($warning, 9) !default;
$notification-error-bg: color-level($error, 10) !default;
$notification-error-text: color-level($error, -6) !default;
$notification-error-border: color-level($error, 9) !default;


// Window
$window-text: $modal-content-color !default;
$window-bg: $modal-content-bg !default;
$window-border: $modal-content-border-color !default;

$window-titlebar-text: $modal-header-color !default;
$window-titlebar-bg: $modal-header-bg !default;
$window-titlebar-border: $modal-header-border-color !default;


// Progress
$progress-bg: $progress-bg !default;
$progress-text: $progress-color !default;
$progress-border: transparent !default;
$progress-gradient: false !default;

$progress-fill-bg:  $progress-bar-bg !default;
$progress-fill-text: $progress-bar-color !default;
$progress-fill-border: transparent !default;
$progress-fill-gradient: false !default;


// Chart
/// The color of the first series.
/// @group charts
$series-a: #0275d8 !default;

/// The color of the second series.
/// @group charts
$series-b: #5bc0de !default;

/// The color of the third series.
/// @group charts
$series-c: #5cb85c !default;

/// The color of the fourth series.
/// @group charts
$series-d: #f0ad4e !default;

/// The color of the fifth series.
/// @group charts
$series-e: #e67d4a !default;

/// The color of the sixth series.
/// @group charts
$series-f: #d9534f !default;

$chart-font-size: $font-size !default;
$chart-font-size-sm: 11px !default;
$chart-font-size-md: 12px !default;
$chart-font-size-lg: 16px !default;

$chart-inactive: rgba( $text-color, .5 ) !default;

/// The color of the Chart grid lines (major).
/// @group charts
$chart-major-lines: rgba(0, 0, 0, .08) !default;

/// The color of the Chart grid lines (minor).
/// @group charts
$chart-minor-lines: rgba(0, 0, 0, .04) !default;

$chart-area-opacity: .8 !default;

// Icons
$icon-size: 16px;
$icon-size-lg: 32px;
$icon-spacing: ($spacer / 2) !default;

// Disabled mixin variables
$disabled-filter: grayscale(.1) !default;
$disabled-opacity: .65 !default;

// Pager
$pager-padding-x: $toolbar-padding-x !default;
$pager-padding-y: $toolbar-padding-y !default;


// Card
$card-padding-x: $card-spacer-x !default;
$card-padding-y: $card-spacer-y !default;
$card-border-width: 1px !default;
$card-border-radius: $card-border-radius !default;
$card-box-shadow: false !default;

$card-deck-gap: $card-deck-margin !default;

$card-bg: $card-bg !default;
$card-text: $panel-text !default;
$card-border: $card-border-color !default;

$card-header-padding-x: $card-padding-x !default;
$card-header-padding-y: $card-padding-y !default;
$card-header-border-width: $card-border-width !default;
$card-header-bg: $card-cap-bg !default;
$card-header-text: $card-text !default;
$card-header-border: $card-border-color !default;

$card-body-padding-x: $card-padding-x !default;
$card-body-padding-y: $card-padding-y !default;

$card-title-margin-bottom: $h5-margin-bottom !default;
$card-subtitle-margin-bottom: $h6-margin-bottom !default;

$card-actions-padding-x: $card-padding-x !default;
$card-actions-padding-y: ($card-padding-y - $button-padding-y) !default;
$card-actions-border-width: 1px !default;


// Chat
$chat-padding-x: 16px !default;
$chat-padding-y: 16px !default;

$chat-item-spacing-x: 8px !default;
$chat-item-spacing-y: 16px !default;

$chat-message-list-padding-x: $chat-padding-x !default;
$chat-message-list-padding-y: $chat-padding-y !default;
$chat-message-list-spacing: $chat-item-spacing-y !default;

$chat-bubble-padding-x: 16px !default;
$chat-bubble-padding-y: 8px !default;
$chat-bubble-spacing: 2px !default;
$chat-bubble-line-height: 1.25 !default;

$chat-bubble-border-radius: 12px !default;
$chat-bubble-border-radius-sm: 2px !default;

$chat-avatar-size: 32px !default;
$chat-avatar-spacing: $chat-item-spacing-x !default;

$chat-message-box-padding-x: $chat-padding-x !default;
$chat-message-box-padding-y: 10px !default;

$chat-quick-reply-padding-x: 12px !default;
$chat-quick-reply-padding-y: 8px !default;
$chat-quick-reply-spacing: 8px !default;
$chat-quick-reply-line-height: $chat-bubble-line-height !default;


$chat-bg: $popup-bg !default;
$chat-text: $popup-text !default;
$chat-border: $popup-border !default;

$chat-bubble-bg: $button-bg !default;
$chat-bubble-text: $button-text !default;
$chat-bubble-border: $chat-bubble-bg !default;

$chat-alt-bubble-bg: $accent !default;
$chat-alt-bubble-text: $accent-contrast !default;
$chat-alt-bubble-border: $chat-alt-bubble-bg !default;


// Config maps
$typography-config: () !default;
// sass-lint:disable indentation
$typography-config: map-merge((
    display-1: font-map($display1-font-size, $display-line-height, $display1-weight, inherit),
    display-2: font-map($display2-font-size, $display-line-height, $display2-weight, inherit),
    display-3: font-map($display3-font-size, $display-line-height, $display3-weight, inherit),
    display-4: font-map($display4-font-size, $display-line-height, $display4-weight, inherit),
    h1: font-map($h1-font-size, normal, $headings-font-family, $headings-font-family),
    h2: font-map($h2-font-size, normal, $headings-font-family, $headings-font-family),
    h3: font-map($h3-font-size, normal, $headings-font-family, $headings-font-family),
    h4: font-map($h4-font-size, normal, $headings-font-family, $headings-font-family),
    h5: font-map($h5-font-size, normal, $headings-font-family, $headings-font-family),
    h6: font-map($h6-font-size, normal, $headings-font-family, $headings-font-family),
    body: font-map($font-size-base, $line-height-base, $font-weight-base, $font-family-base)
), $typography-config);
$typography-config: map-merge((
    card-title: map-get($typography-config, h5),
    card-subtitle: map-get($typography-config, h6)
), $typography-config);
// sass-lint:enable indentation

// import any default values from kendo-theme-default
@import "../modules/@progress/kendo-theme-default/scss/variables";
