﻿.link-icon {
    width: 2em;
    display: inline-flex;
    justify-content: center;
}

.btn--icon {
    &, &:hover, &:focus {
        text-decoration: none;
    }

    background-color: transparent;
}
