﻿.lp-center {
    @include media-breakpoint-up(sm) {
        align-items: center;
        height: 100vh;
    }
}

.lp-login__bg {
    & span:before {
        content: '';
        position: absolute;
        width: 30%;
        height: 120vh;
        transform: skewY(45deg);
        z-index: -1;
    }

    & span:first-child:before {
        left: 0;
        background: #fff;
        background: linear-gradient(to right, #e2e7ef, $body-bg 100%);
    }

    & span:nth-child(2):before {
        right: 0;
        background: #fff;
        background: linear-gradient(to left, #e2e7ef, $body-bg 100%);
        top: 60vh;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

//$login-btn--color1: mix($primary, white, 80%);
//$login-btn--color2: mix($primary, black, 80%);

.login-btn {
    position: relative;
    z-index: 100;
    background: linear-gradient(-15deg, #3a9cff 0%, #003c77 100%);
    //background: linear-gradient(-15deg, hsl(210, 100%, 61%) 0%, hsl(210, 100%, 23%) 100%);
    //background: linear-gradient(-15deg, lighten($primary, 39) 0%, darken($primary, 23) 100%);
    background-size: 100% 350%;
    border: none;
    transition: .3s cubic-bezier(.645, .045, .355, 1) background;
    font-size: 1em;
    padding: 0.6rem 0rem;
    /*&:before {
        display: block;
        background-image: linear-gradient(to right, $login-btn--color2 0%, $login-btn--color1 100%);
        outline: none;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -100;
        opacity: 0;
        top: 0;
        left: 0;
        border-radius: $border-radius;
        border-radius: inherit;
        transition: opacity .3s cubic-bezier(.645, .045, .355, 1);
    }

    &:hover:before, &:focus:before {
        opacity: 1;
    }*/
    &:hover, &:focus {
        background-size: 100% 100%;
    }

    &:active {
        background-size: 100% 100%;
        //need !important to override Bootstrap default active state
        opacity: 0.95;
        background: linear-gradient(-15deg, #3a9cff 0%, #003c77 100%) !important;
    }
}

.footer-logo {
    width: 4.5rem;
    // transform: translate(0.25rem, -0.25rem);
}
.sidenav {
    footer {
        position: absolute;
        bottom: 0;
        left: 2rem;
    }

    .footer-logo {
        width: 4.5rem;
        // transform: translate(0.25rem, -0.25rem);
    }
    small {
        align-items: center;
        padding: 1rem;
    }
    .footer-copyright {
        padding-right: 0.5rem;
    }
}

.login-footer {
    &--large {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        align-items: center;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    
    &--small {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    &--small, &--large {
        small {
            align-items: center;
            padding: 1rem;
        }
        .footer-copyright {
            padding-right: 0.5rem;
        }
    }
}

.login-body {
    @include media-breakpoint-up(md) {
        overflow: hidden;
    }
    min-height: 0;
    @include media-breakpoint-down(xs) {
        padding-top: 2rem;
    }
}