﻿.utilities {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include list-unstyled;
    margin: 0;

    &__item {
        & a {
            color: $dark;
            text-decoration: none;
            position: relative;
        }

        &--badge {
            position: absolute;
            top: 0.2rem;
            right: 0.35rem;
            background: red;
            width: 1rem;
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 800;
            font-size: 0.75rem;
            border-radius: 1rem;
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
