@include exports("notification/theme") {

    // Theme
    .k-notification {
        @include appearance( panel );
    }
    .k-notification-info {
        border-color: $notification-info-border;
        color: $notification-info-text;
        background-color: $notification-info-bg;
    }
    .k-notification-success {
        border-color: $notification-success-border;
        color: $notification-success-text;
        background-color: $notification-success-bg;
    }
    .k-notification-warning {
        border-color: $notification-warning-border;
        color: $notification-warning-text;
        background-color: $notification-warning-bg;
    }
    .k-notification-error {
        border-color: $notification-error-border;
        color: $notification-error-text;
        background-color: $notification-error-bg;
    }

}