@include exports("button/theme") {

    // Default button
    .k-button {
        @include appearance( button );
        transition: $transition;

        // Hover state
        &:hover,
        &.k-state-hover {
            @include appearance( hovered-button );
        }

        // Focused
        &:focus,
        &.k-state-focused {
            box-shadow: $button-focused-shadow;
        }

        // Active state
        &:active,
        &.k-state-active {
            @include appearance( pressed-button );
        }

        // Selected state
        &.k-state-selected {
            @include appearance( pressed-button );
        }

    }


    // Primary button
    .k-button.k-primary {
        @include appearance( primary-button );

        // Hover state
        &:hover,
        &.k-state-hover {
            @include appearance( hovered-primary-button );
        }

        // Focused
        &:focus,
        &.k-state-focused {
            box-shadow: $primary-button-focused-shadow;
        }

        // Active state
        &:active,
        &.k-state-active {
            @include appearance( pressed-primary-button );
        }

        // Selected state
        &.k-state-selected {
            @include appearance( pressed-primary-button );
        }

    }


    // Button group
    .k-button-group {
        border: 0;

        // Hover state
        > input[type="radio"]:hover + .k-button,
        > input[type="checkbox"]:hover + .k-button {
            @include appearance( hovered-button );
        }
        .k-button:active,
        .k-button.k-state-active {
            & + .k-button:hover,
            & + .k-button.k-state-hover {
                border-left-color: $button-pressed-border;
            }
        }

        // Focused state
        > input[type="radio"]:focus + .k-button,
        > input[type="checkbox"]:focus + .k-button {
            box-shadow: $button-focused-shadow;
        }

        // Selected state
        > input[type="radio"]:checked + .k-button,
        > input[type="checkbox"]:checked + .k-button {
            @include appearance( pressed-button );
        }

        // Disabled state
        &.k-state-disabled .k-button,
        .k-button.k-state-disabled {

            &:not(.k-state-active) {
                @include appearance( button );
            }
        }

    }


    // Badge
    .k-badge {
        background-color: $accent;
        color: $accent-contrast;
    }
}




@include exports( "outline-button/theme" ) {

    // Outline default button
    .k-button.k-outline {
        border-color: currentColor;
        color: $button-text;
        background: none;
        box-shadow: none;

        // Hover state
        &:hover,
        &.k-state-hover {
            @include fill( contrast-wcag( $button-text ), $button-text, $button-text, false );
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            box-shadow: 0 0 0 3px rgba( $button-text, .5 );
        }

        // Active state
        &:active,
        &.k-state-active {
            @include fill( contrast-wcag( $button-text ), $button-text, $button-text, false );
        }

        // Selected state
        &.k-state-selected {
            @include fill( contrast-wcag( $button-text ), $button-text, $button-text, false );
        }
    }
    .k-button.k-outline.k-primary {
        border-color: currentColor;
        color: $primary-button-bg;
        background: none;
        box-shadow: none;

        // Hover state
        &:hover,
        &.k-state-hover {
            @include fill( contrast-wcag( $primary-button-bg ), $primary-button-bg, $primary-button-bg, false );
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            box-shadow: 0 0 0 3px rgba( $primary-button-bg, .5 );
        }

        // Actove state
        &:active,
        &.k-state-active {
            @include fill( contrast-wcag( $primary-button-bg ), $primary-button-bg, $primary-button-bg, false );
        }

        // Selected state
        &.k-state-selected {
            @include fill( contrast-wcag( $primary-button-bg ), $primary-button-bg, $primary-button-bg, false );
        }
    }

}




@include exports( "flat-button/theme" ) {

    // Flat button
    .k-button.k-flat,
    .k-button.k-bare {
        color: inherit;

        &::after {
            display: block;
        }

        &:focus,
        &.k-state-focused {

            &::after {
                box-shadow: 0 0 0 3px currentColor;
                opacity: .25;
            }
        }
    }
    .k-button.k-primary.k-flat,
    .k-button.k-primary.k-bare {
        color: $accent;
    }

}
