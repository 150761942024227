// Button inner size should be calculated with the widget font-size instead of the icon-size
$close-icon-position-y: calc(#{$input-padding-x} + #{$button-padding-y} * 2 + #{$form-line-height * $font-size}) !default;; // $input-padding-x + button-inner-size()

@include exports("combobox/layout") {

    .k-combobox {
        .k-dropdown-wrap {
            > .k-i-close {
                right: $close-icon-position-y;
            }
        }

        .k-i-loading {
            position: relative;
            right: auto;
            bottom: auto;
            margin: 0;
        }

        &[dir='rtl'],
        .k-rtl & {
            > .k-dropdown-wrap {
                > .k-i-close {
                    left: $close-icon-position-y;
                    right: auto;
                }
            }
        }
    }

    .k-combobox-clearable {
        .k-input {
            padding-right: add-two($icon-size, $padding-x);
        }

        &[dir='rtl'],
        .k-rtl & {
            .k-input {
                padding-left: add-two($icon-size, $padding-x);
                padding-right: $input-padding-x;
            }
        }
    }
}
