@include exports("tooltip/theme") {

    .k-tooltip {
        font-size: $tooltip-font-size;
        color: contrast-wcag($tooltip-bg);
        background-color: $tooltip-bg;
        @include border-radius($border-radius);
    }


    .k-callout-n { border-bottom-color: $tooltip-bg; }
    .k-callout-e { border-left-color: $tooltip-bg; }
    .k-callout-s { border-top-color: $tooltip-bg; }
    .k-callout-w { border-right-color: $tooltip-bg; }

}
