@include exports("pager/layout") {
    $pager-buttons-size: calc(#{$form-line-height-em} + 2px) !default; /// icon button size + 2px border (top and bottom)
    $pager-buttons-padding: $button-padding-y !default;

    .k-pager-wrap {
        padding: $pager-padding-y $pager-padding-x;
        border-width: 1px;
        line-height: $form-line-height;
        display: flex;
        align-items: center;
        overflow: hidden;
        cursor: default;
        flex: 0 0 auto;

        .k-link,
        .k-state-selected {
            padding: $pager-buttons-padding;
            width: $pager-buttons-size;
            height: $pager-buttons-size;
            line-height: $pager-buttons-size;
            box-sizing: content-box;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            text-align: center;
            z-index: 1;

            > .k-icon {
                vertical-align: middle;
            }

        }
        .k-link:hover,
        .k-state-selected {
            z-index: 2;
        }
        .k-link:focus,
        .k-state-selected {
            text-decoration: none;
            outline: none;
        }
        .k-link.k-state-disabled {
            color: inherit;
        }
        > .k-pager-nav {}
        > .k-pager-nav + .k-pager-nav {}


        .k-pager-numbers {
            display: inline-flex;
            flex-direction: row;

            li {
                display: inline-block;
            }

            .k-current-page {
                display: none;
            }
            .k-current-page + li {
                margin-left: 0;
            }
        }

        .k-label {
            margin: 0 $spacer-x;
            display: flex;
            align-items: center;
        }
        .k-pager-input {
            .k-textbox {
                margin: 0 1ex;
                width: 3em;
            }
        }
        .k-pager-sizes {
            .k-dropdown,
            > select {
                width: 5.2em;
                margin: 0 $padding-x;
            }
        }
        .k-pager-refresh {
            order: 10;
        }
        .k-pager-info {
            flex: 1;
            text-align: right;
            order: 9;
            justify-content: flex-end;
        }

        [dir = "rtl"] &,
        .k-rtl & {
            .k-i-arrow-e,
            .k-i-arrow-w,
            .k-i-seek-e,
            .k-i-seek-w {
                transform: scaleX(-1);
            }
        }
    }
}
