@include exports("grid/theme") {
    $grid-cell-focused-shadow: inset 0 0 0 2px rgba( 0, 0, 0, .08) !default;
    $grid-sorted-bg: rgba( contrast-wcag( $base-bg ), .02 ) !default;

    // Theme
    .k-grid {
        border-color: $grid-border;
        color: $grid-text;
        background-color: $grid-bg;

        td,
        .k-grid-content,
        .k-grid-header-locked,
        .k-grid-content-locked {
            border-color: $grid-border;
        }

        // Alt row
        .k-alt {
            color: $grid-alt-text;
            background-color: $grid-alt-bg;
        }

        // Hover state
        tbody tr:hover {
            color: $grid-hovered-text;
            background-color: $grid-hovered-bg;
        }

        // Selected state
        tr.k-state-selected,
        th.k-state-selected,
        td.k-state-selected {
            border-color: inherit;
            color: inherit;
            background-color: rgba( $selected-bg, .25 );
        }

        // Focused state
        td.k-state-focused,
        th.k-state-focused {
            box-shadow: $grid-cell-focused-shadow;
        }

        .k-tooltip.k-tooltip-validation {
            color: $tooltip-color;
            background-color: $tooltip-bg;

            .k-callout-n {
                border-bottom-color: $tooltip-bg;
            }

            .k-callout-e {
                border-left-color: $tooltip-bg;
            }

            .k-callout-s {
                border-top-color: $tooltip-bg;
            }

            .k-callout-w {
                border-right-color: $tooltip-bg;
            }
        }

        col.k-sorted,
        th.k-sorted {
            background-color: $grid-sorted-bg;
        }

        .k-dirty {
            border-color: currentColor transparent transparent currentColor;
        }

        &[dir = "rtl"],
        .k-rtl & {
            .k-dirty {
                border-color: currentColor currentColor transparent transparent;
            }
        }

        .k-button {
            @include appearance( button );

            &:hover {
                @include appearance( hovered-button );
            }
        }
    }


    // Toolbar
    .k-grid-toolbar {
        @include appearance( toolbar );
        padding: $toolbar-padding-y;
    }


    // Grouping header
    .k-grouping-header {
        @include fill( $grid-header-text, $grid-header-bg, $grid-header-border );
    }
    .k-grouping-header .k-group-indicator {
        @include appearance( button );
        @include border-radius( $border-radius );
    }


    // Header
    .k-grid-header {
        @include fill( $grid-header-text, $grid-header-bg, $grid-header-border );

        .k-i-sort-asc-sm,
        .k-i-sort-desc-sm,
        .k-sort-order {
            color: $accent;
        }

        .k-sort-order {
            font-size: ($icon-size * .75);
            margin-left: -4px;
            line-height: 1;
            vertical-align: text-top;
            margin-top: 2px;
        }

        .k-grid-filter {}
        .k-grid-filter:focus {
            box-shadow: inset 0 0 0 2px rgba( 0, 0, 0, .1 );
        }
        .k-grid-filter.k-state-active {
            color: $selected-text;
            background-color: $selected-bg;
        }

    }
    .k-grid-footer {
        @include fill( $grid-header-text, $grid-header-bg, $grid-header-border );

        .k-grid-footer-locked {
            border-color: inherit;
        }
    }


    // Pager
    .k-grid-pager {
        @include fill( $grid-header-text, $grid-header-bg, $grid-header-border );
        padding: $toolbar-padding-y;
    }


    // Grouping
    .k-grouping-row,
    .k-group-cell {
        @include fill( $grid-header-text, $grid-header-bg, $grid-header-border );
    }

    .k-column-list-item:hover,
    .k-columnmenu-item:hover {
        @include appearance( hovered-list-item );
    }

    .k-column-list-wrapper {
        border-color: inherit;
    }
}
