﻿.k-tabstrip {
    .k-content {
        background-color: transparent;
        border-width: 0;
        padding: 0;
        overflow: visible;

        .k-grid {
        }
    }
}

.k-tabstrip > .k-tabstrip-items .k-item {
    border: none;

    &:focus {
        outline: 0;
        border-radius: 0.8rem 0.8rem 0 0;
        background-color: rgba($primary, 0.06);
    }
}

.k-tabstrip-items {
    padding-bottom: 1rem;

    .k-item.k-state-default:not(.k-state-active) .k-link {
        &:hover,
        &:focus {
            border-bottom: 3px solid $gray-400;
        }
    }

    .k-item.k-state-active .k-link {
        color: $primary;
        border-bottom: 3px solid $primary;
    }
}