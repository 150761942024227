@include exports("panelbar/theme") {

    // Appearance
    .k-panelbar {
        @include appearance( panel );

        // Root
        > .k-item {
            border-color: $header-border;
        }
        > .k-item > .k-link {
            // @include appearance( header );
            border-color: $header-border;
            color: $header-text;
            background-color: $header-bg;
        }
        > .k-item > .k-link:hover,
        > .k-item > .k-link.k-state-hover {
            @include appearance( hovered-header );
        }
        > .k-item > .k-link.k-state-selected {
            @include appearance( selected-header );
        }
        > .k-item > .k-link.k-state-focused {
            box-shadow: inset 0 0 0 3px try-darken( $header-bg, 7 );

            &:hover,
            &.k-state-hover {
                box-shadow: inset 0 0 0 3px try-darken( $header-hovered-bg, 7 );
            }

            &.k-state-selected {
                box-shadow: inset 0 0 0 3px try-darken( $header-selected-bg , 7 );
            }
        }

        // Child
        .k-panel > .k-item > .k-link {}

        .k-panel > .k-item > .k-link:hover,
        .k-panel > .k-item > .k-link.k-state-hover {
            @include appearance( hovered-node );
        }
        .k-panel > .k-item > .k-link.k-state-selected {
            @include appearance( selected-node );
        }
        .k-panel > .k-item > .k-link.k-state-focused {
            box-shadow: inset 0 0 0 3px try-darken( $node-bg, 7 );

            &:hover,
            &.k-state-hover {
                box-shadow: inset 0 0 0 3px try-darken( $node-hovered-bg , 7 );
            }

            &.k-state-selected {
                box-shadow: inset 0 0 0 3px try-darken( $node-selected-bg , 7 );
            }
        }

    }

}