$panelbar-header-padding-x: $header-padding-x !default;
$panelbar-header-padding-y: $header-padding-y !default;

@include exports("panelbar/layout") {

    // Base
    .k-panelbar {
        margin: 0;
        padding: 0;
        list-style: none;


        // Root
        > .k-item {
            // TODO
            border-width: 0;
            border-style: solid;
            border-color: inherit;
            display: block;

            > .k-link {
                padding: $panelbar-header-padding-y $panelbar-header-padding-x;
                color: inherit;
                background: none;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $transition;
            }
        }
        > .k-item + .k-item {
            border-top-width: 1px;
        }


        // Sub
        .k-group {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .k-group > .k-item {
            display: block;

            > .k-link {
                padding: $list-item-padding-y $list-item-padding-x;
                color: inherit;
                text-decoration: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $transition;
            }
        }


        // Expand collapse
        .k-panelbar-expand,
        .k-panelbar-collapse {
            margin-top: -.5em;
            position: absolute;
            top: 50%;
            right: $panelbar-header-padding-x;
        }

        .k-link > .k-image,
        .k-link > .k-sprite {
            vertical-align: middle;
            float: left;
            margin-right: 5px;
        }

        &[dir='rtl'],
        .k-rtl & {
            .k-panelbar-expand,
            .k-panelbar-collapse {
                right: auto;
                left: $panelbar-header-padding-x;
            }
        }
    }

}
