﻿$toggle-size: 32px;
$bar-size: $toggle-size / 8;
$bar-space: ($toggle-size - ($bar-size * 5)) / 2;

$bar2-top: $bar-size + $bar-space;
$bar3-top: $bar2-top * 2;

.mobile-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 3em;
    left: 0;
    width: 100%;
    height: 0;
    transition: .5s cubic-bezier(.5, .25, .015, 1) height;
    transition-delay: 0s;
    background: white;
    overflow: hidden;
    z-index: 3;

    ul {
        list-style: none;
    }

    li {
        margin: 2em 0;

        @include media-breakpoint-down(sm) {
            margin: 1em 0;
        }
    }

    a {
        color: $primary;
        text-decoration: none;
        font-size: 2em;
        display: block;
        padding: 0.25em 0.5em;

        &:hover, &:focus {
            box-shadow: 0 0 0 4px $primary;
        }
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.mobile-nav__toggle {
    display: block;
    width: $toggle-size;
    height: $toggle-size;
    cursor: pointer;
    transform: rotate(0deg);

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.mobile-nav__subNav {
    a {
        color: $primary;
        text-decoration: none;
        font-size: 1em;
        display: block;
        padding: 0em 0.5em;

        &:hover, &:focus {
            box-shadow: 0 0 0 4px $primary;
        }
    }
}

.mobile-nav__toggle span {
    background-color: black;
    width: 100%;
    height: $bar-size;
    border-radius: $bar-size / 4;
    display: block;
    position: absolute;
    left: 0;
    content: '';
    // transition time and time function
    transition: .5s ease-in-out;
}

.mobile-nav__toggle {
    span {
        &:nth-child(1) {
            top: $bar-size;
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: $bar2-top + $bar-size;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: $bar3-top + $bar-size;
            transform-origin: left center;
        }
    }

    &.open span {
        &:nth-child(1) {
            transform: rotate(45deg);
            top: -($toggle-size / 32) + $bar-size;
            left: $bar-size;
        }

        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
            top: $bar3-top + ($toggle-size / 32) + $bar-size;
            left: $bar-size;
        }
    }
}

.mobile-nav.open {
    height: calc(100vh - 4rem);
}

.mobile-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
}
