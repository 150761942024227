@include exports("editor/theme") {

    $editor-overlay-background: #000 !default;

    .k-editor {
        @include appearance( panel );

        .k-editor-toolbar {
            @include appearance( toolbar );
        }
        .k-editable-area {
            border-color: $header-border;
        }
    }

    // Inline Editor
    .k-editor-inline {

        // Hover & Actve state
        &:hover,
        &.k-state-active {
            border-color: $panel-border;
        }
    }

    .k-overlay {
        background-color: $editor-overlay-background;
    }


    // Editor toolbar
    .k-editor-toolbar {

        a.k-tool {
            @include appearance( button );

            &:hover,
            &.k-state-hover {
                @include appearance( hovered-button );
            }

            &:active,
            &.k-state-active {
                @include appearance( pressed-button );
            }

            &.k-state-selected {
                @include appearance( pressed-button );
            }
        }
    }

    // sass-lint:disable class-name-format
    .editorToolbarWindow.k-header.k-window-content {
        @include appearance( header );
    }
    // sass-lint:enable class-name-format
    // sass-lint:disable class-name-format
    .k-editor .k-editor-toolbar-wrap,
    .k-ct-popup .k-editor-toolbar,
    .editorToolbarWindow.k-header.k-window-content {
        .k-tool-group {
            border-color: $border-color;
        }
    }
    // sass-lint:enable class-name-format


    // Overflow anchor
    .k-editor-toolbar .k-overflow-anchor {
        padding: $toolbar-padding-y;
        width: calc( #{$toolbar-inner-calc-size} );
        height: calc( #{$toolbar-inner-calc-size} );
    }

    .k-editor-dialog {
        .k-edit-label {
            padding: add-two($padding-y, -$input-border-width) 0;
        }
    }
}