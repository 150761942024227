@include exports("combobox/theme") {

    // Combobox
    .k-combobox {

        // Normal state
        .k-dropdown-wrap {
            @include appearance( input );
            transition: $transition;
        }
        .k-select {
            @include appearance( button );
            transition: $transition;
        }

        // Hovered state
        > :hover,
        .k-state-hover {
            @include appearance( hovered-input );

            .k-select {
                @include appearance( hovered-button );
            }
        }

        // Focused state
        .k-state-focused {
            @include appearance( focused-input );
        }

        // Selected state
        .k-state-active {
            @include appearance( focused-input );

            .k-select {
                @include appearance( pressed-button );
            }
        }

        &[dir='rtl'],
        .k-rtl & {
            .k-select {
                @include border-radius( $border-radius 0 0 $border-radius );
                border-width: 0 1px 0 0;
            }
        }
    }

}
