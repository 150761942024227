@include exports( "common/utils/reset" ) {

    // Reset
    .k-reset {
        margin: 0;
        padding: 0;
        border-width: 0;
        outline: 0;
        font: inherit;
        text-decoration: none;
        list-style: none;
    }

}
