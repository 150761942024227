@include exports( "common/selection/text-selection" ) {

    %text-selection {
        ::selection {
            background-color: $accent;
            color: $accent-contrast;
        }
    }

}


@include exports( "common/selection/marquee" ) {

    .k-marquee {
        position: absolute;
        z-index: 100000;
    }
    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .k-marquee-color {
        color: $selected-text;
        background-color: $selected-bg;
        border-color: $selected-border;
        opacity: .6;
    }
    .k-marquee-text {
        color: $selected-text;
    }

}
