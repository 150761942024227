﻿.sidenav {
    background: $white;
    color: $black;
    box-shadow: $box-shadow-horizontal;
    height: 100%;
    max-width: 14rem;
    overflow: auto;

    &.active {
        min-width: 60px;
        max-width: 60px;
        text-align: center;

        ul li a {
            span {
                display: none;
            }
        }
    }

    ul li a {
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__list {
        margin: 0;
        padding: 0.5rem;
        list-style: none;

        & hr {
            margin: 0 1.5rem;
        }

        &-item {
            a {
                padding: 0.5rem 0.5rem 0.5rem 0.75rem;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: inherit;
                transition: $btn-transition;
                border-radius: 0.8rem;
                outline: 0;

                &:hover {
                    background-color: rgba($primary, 0.15);
                    color: $primary;
                }

                &:focus {
                    background-color: rgba($primary, 0.15);
                    color: inherit;
                    box-shadow: 0 0 0 3px rgba($primary, .5);
                }

                &:active {
                    box-shadow: 0;
                    background-color: rgba($primary, 0.15);
                }

                &:not([href]) {
                    color: inherit;
                    cursor: pointer;
                }
            }

            &.active>a {
                font-weight: $font-weight-bold;
                background-color: rgba($primary, 0.15);
                color: $primary;
            }

            & ul a {
                padding: 0.25rem;
                padding-left: 2.5rem;
            }
        }

        .link-icon {
            margin-right: 1rem;
        }
    }

    &__sublist {
        margin: 0;
        list-style: none;
        @include list-unstyled;

        &-item {
            a {
                padding: 0.5rem 0.5rem 0.5rem 0.75rem;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: inherit;
                transition: $btn-transition;
                border-radius: 0.8rem;
                outline: 0;

                &:hover {
                    background-color: rgba($primary, 0.15);
                    color: $primary;
                }

                &:focus {
                    background-color: rgba($primary, 0.15);
                    color: inherit;
                    box-shadow: 0 0 0 3px rgba($primary, .5);
                }

                &:active {
                    box-shadow: 0;
                    background-color: rgba($primary, 0.15);
                }

                &:not([href]) {
                    cursor: pointer;

                    &:active {
                        box-shadow: 0 0 0 3px rgba($primary, .5)
                    }
                }
            }

            &.active a {
                font-weight: $font-weight-bold;
                background-color: rgba($primary, 0.15);
                color: $primary;
            }

            & ul a {
                padding: 0.25rem;
                padding-left: 3rem;
            }
        }

        .link-icon {
            margin-right: 1rem;
        }
    }

    &__logo {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        color: $primary;
        transition: $btn-transition;

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: rgba($primary, 0.15);
            color: $primary;
        }
    }
}

.sidenav__variableHeight {
    margin-bottom: 2.5rem;
}
.sidebar-toggler{
    display: block;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}