@include exports("scroller/layout") {

    // Layout
    .km-scrollview,
    .km-scroll-container {
        user-select: none;
        margin-collapse: separate;
    }
    .km-scroll-wrapper {
        position: relative;
    }
    .km-scroll-header {
        position: absolute;
        z-index: 1001;
        width: 100%;
        top: 0;
        left: 0;
    }


    // Pull to refresh
    .km-scroller-pull {
        width: 100%;
        display: block;
        position: absolute;
        line-height: 3em;
        font-size: 1.4em;
        text-align: center;
        transform: translate3d(0, -3em, 0);
    }
    .km-scroller-pull .km-template {
        display: inline-block;
        min-width: 200px;
        text-align: left;
    }
    .km-load-more .km-icon,
    .km-widget .km-scroller-pull .km-icon {
        display: inline-block;
        height: 2rem;
        margin-right: 1rem;
        vertical-align: middle;
        width: 2rem;
        font-size: 2rem;
        transform: rotate(0deg);
        transition: transform 300ms linear;
    }
    .km-widget .km-scroller-release .km-icon {
        transform: rotate(180deg);
    }
    .km-widget .km-scroller-refresh .km-icon {
        transition: none;
    }


    // Scrollbar
    .km-touch-scrollbar {
        position: absolute;
        visibility: hidden;
        z-index: 200000;
        height: .4em;
        width: .4em;
        background-color: #333;
        opacity: 0;
        transform-origin: 0 0;
        transition: opacity .3s linear;
    }
    .km-vertical-scrollbar {
        height: 100%;
        right: 2px;
        top: 0;
    }
    .km-horizontal-scrollbar {
        width: 100%;
        left: 0;
        bottom: 2px;
    }

}
