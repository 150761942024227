@include exports('upload/layout') {
    $line-height-sm: (20 / 12) !default;
    $uploaded-image-width: 24px !default;
    $uploaded-image-height: 34px !default;
    $uploaded-image-border: 2px !default;
    $uploaded-file-spacing: $cell-padding !default;

    $upload-button-margin: $toolbar-padding-x !default;
    $upload-dropzone-status-icon-spacing: $padding-x-lg / 2 !default;
    $upload-status-spacing: $button-padding-y $button-padding-x !default;
    $upload-file-name-spacing: $padding-y !default;

    $upload-progress-thickness: 2px !default;

    html .k-upload {
        position: relative;
    }

    .k-upload {
        .k-upload-button {
            min-width: 7em;
            margin: $upload-button-margin;
        }

        // Drop zone
        .k-dropzone {
            display: flex;
            align-items: center;
            position: relative;
            border-width: 0;
            background-color: transparent;

            // https://github.com/telerik/kendo/issues/8240
            em, // BACKCOMPAT: ensures backwards compatibility for KendoUI jQuery, remove when rendering changes
            .k-dropzone-hint {
                margin-left: $spacer-x;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 0;
                white-space: nowrap;
                position: relative;
                vertical-align: middle;
                visibility: hidden;
                font-style: italic;
                display: none;
            }

            .k-upload-status {
                display: flex;
                align-items: center;
                position: relative;
                padding: $upload-status-spacing;
                font-size: $font-size;
                font-weight: normal;
                line-height: $form-line-height;

                >.k-icon {
                    margin-right: $upload-dropzone-status-icon-spacing;
                }
            }

            &.k-dropzone-active {
                // https://github.com/telerik/kendo/issues/8240
                em, // BACKCOMPAT: ensures backwards compatibility for KendoUI jQuery, remove when rendering changes
                .k-dropzone-hint {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                }

                .k-upload-status {
                    display: none;
                }
            }
        }

        .k-upload-status-total {
            margin-left: $uploaded-file-spacing;
            margin-right: $uploaded-file-spacing;
        }

        // Upload files list
        .k-upload-files {
            padding-bottom: $padding-y;
            border-width: 1px 0 0;
            border-style: solid;
            border-color: inherit;
            margin: 0;

            .k-file-multiple,
            .k-file-single {
                display: block;
                width: 100%;
            }

            .k-file {
                padding: $uploaded-file-spacing;
                border-width: 0 0 1px;
                border-style: solid;
                border-color: inherit;
                display: flex;
                align-items: center;
                position: relative;
                line-height: $form-line-height;
            }

            .k-progress {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .k-filename {
                margin-left: $spacer-x;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                position: relative;
            }

            .k-upload-status {
                position: absolute;
                right: $uploaded-file-spacing;
                top: $uploaded-file-spacing;

                .k-button {
                    padding: 0;
                    border-radius: 50%;
                }

                .k-icon {
                    vertical-align: middle;
                }
            }

            .k-upload-pct {
                font-weight: normal;
                vertical-align: middle;
            }

            ~.k-clear-selected,
            ~.k-upload-selected {
                margin-top: -$padding-y;
                border-width: 0;
            }

            ~.k-upload-selected {
                border-left-width: 1px;
                border-left-style: solid;
                margin-left: -1px;
            }

            .k-file-name,
            .k-file-size,
            .k-file-validation-message,
            .k-file-information {
                display: block;
            }

            .k-file-name {
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: $upload-file-name-spacing;
            }

            .k-file-size,
            .k-file-information,
            .k-file-validation-message {
                font-size: $font-size-sm * .786;
            }

            .k-file-information {
                text-indent: 1px;
            }

            .k-file-extension-wrapper,
            .k-multiple-files-extension-wrapper,
            .k-file-invalid-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                position: absolute;
                top: $uploaded-file-spacing;
                width: $uploaded-image-width;
                height: $uploaded-image-height;
                border-width: $uploaded-image-border;
                border-style: solid;
                vertical-align: top;
                font-size: $font-size * .57;
                text-transform: uppercase;
                margin: ($uploaded-image-border / 2) 0;
                box-sizing: content-box;
            }

            .k-file-invalid-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                font-size: 1.2em;
            }

            .k-multiple-files-extension-wrapper,
            .k-multiple-files-invalid-extension-wrapper {
                margin-top: $padding-y;
            }

            .k-file-state {
                visibility: hidden;
            }

            .k-file-name-size-wrapper {
                display: block;
                margin-left: calc(#{$uploaded-image-width} + #{$spacer-x});
                margin-right: calc(#{$icon-size}*2 + 3.5em);
                overflow: hidden;
                min-height: $uploaded-image-height + ($uploaded-image-border * 2);
            }

            .k-file-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::after,
            .k-file-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::after {
                position: absolute;
                content: '';
                display: inline-block;
                border-style: solid;
            }

            .k-file-extension-wrapper::before,
            .k-multiple-files-extension-wrapper::before,
            .k-file-invalid-extension-wrapper::before,
            .k-multiple-files-invalid-extension-wrapper::before {
                top: -1px;
                right: -1px;
                width: 0;
                height: 0;
                border-width: 6px;
                margin-top: -1px;
                margin-right: -1px;
            }

            .k-multiple-files-extension-wrapper::after,
            .k-multiple-files-invalid-extension-wrapper::after {
                top: -6px;
                left: -6px;
                width: 15px;
                height: 35px;
                border-width: 2px 0 0 2px;
            }

            .k-file-extension,
            .k-file-invalid-icon {
                position: absolute;
                bottom: 0;
                line-height: normal;
            }

            .k-file-invalid-icon {
                margin-left: 4px;
            }

            .k-file-extension {
                margin-left: .2em;
                margin-bottom: .3em;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                left: 0;
            }

            .k-upload-action {
                margin-left: $uploaded-file-spacing;
            }
        }

        .k-action-buttons {
            margin: -$padding-y 0 0;
            padding: 0;
            border-top: 0;
        }

        .k-progress {
            position: absolute;
            bottom: 0;
            left: 0;
            height: $upload-progress-thickness;
        }

        .k-file-invalid > .k-progress {
            width: 100%;
        }

        // RTL styles
        &[dir = "rtl"],
        .k-rtl & {
            .k-dropzone .k-upload-status > .k-icon {
                margin-left: $padding-y-lg;
                margin-right: 0;
            }

            .k-upload-files {
                .k-upload-status {
                    right: auto;
                    left: $uploaded-file-spacing;
                }

                ~.k-upload-selected {
                    border-left-width: 0;
                    border-right-width: 1px;
                    border-right-style: solid;
                    margin-left: 0;
                }

                .k-file-invalid-icon {
                    margin-left: 0;
                    left: $padding-x-sm;
                }
            }

            .k-file-name-size-wrapper {
                margin-right: calc(#{$uploaded-image-width} + #{$spacer-x});
                margin-left: calc(#{$icon-size} + #{$uploaded-file-spacing});
            }

            .k-file-extension {
                right: 0;
                left: auto;
                margin-right: .4em;
                margin-left: 0;
            }

            .k-upload-action {
                margin-left: 0;
                margin-right: $uploaded-file-spacing;
            }
        }
    }

    .k-upload-button {
        position: relative;
        overflow: hidden;
        direction: ltr;

        input {
            // font should not depend on outside styles, otherwise upload may break
            font: 170px monospace !important; // sass-lint:disable-block no-important
            margin: 0;
            padding: 0;
            filter: alpha(opacity=0);
            opacity: 0;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }

    .k-ie9 {
        .k-upload-status-total {
            top: 1.5em;

            > .k-icon {
                margin-top: -3px;
            }
        }
        .k-upload-button {
            margin: $padding-y-sm $padding-x-sm;
        }
    }

}
