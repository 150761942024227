@include exports("colorpicker/theme") {

    // Theme
    .k-colorpicker {

        // Normal state
        .k-picker-wrap {
            @include appearance( button );
            transition: $transition;
        }
        .k-select {
            border-color: inherit;
            transition: $transition;
        }

        // Hovered state
        .k-state-hover {
            @include appearance( hovered-button );
        }

        // Focused state
        .k-state-focused {}

        // Active state
        .k-state-active {
            @include appearance( pressed-button );
        }

    }

}