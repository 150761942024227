$toolbar-separator-border: inherit !default;

@include exports("toolbar/theme") {

    // Theme
    .k-toolbar {
        @include appearance( toolbar );

        // Separator
        .k-separator {
            border-color: $toolbar-separator-border;
        }
    }

}
