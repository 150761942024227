﻿@import "@progress/kendo-theme-bootstrap/modules/@progress/kendo-theme-default/scss/mixins/core/config-maps";
@import "@progress/kendo-theme-bootstrap/modules/@progress/kendo-theme-default/scss/mixins/colors";
@import "Bootstrap/functions";
@import "@progress/kendo-theme-bootstrap/scss/bootstrap-overrides";
@import "custom-bootstrap-variables";
@import "Bootstrap/variables";


// Options
$enable-rounded: $enable-rounded;
$enable-shadows: $enable-shadows;
$enable-gradients: $enable-gradients;
$enable-transitions: $enable-transitions;


// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
/// @group color-system
$accent: $primary;

/// The color used along with the accent color denoted by $accent.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
$accent-contrast: contrast-wcag( $accent );

/// The color for error messages and states.
/// @group color-system
$success: $success;

/// The color for warning messages and states.
/// @group color-system
$info: $info;

/// The color for success messages and states.
/// @group color-system
$warning: $warning;

/// The color for informational messages and states.
/// @group color-system
$error: $danger;

/// Set a specific jump point for requesting color jumps
/// @group color-system
$color-level-step: $theme-color-interval;

$rgba-transparent: rgba( 0, 0, 0, 0 );


// Contrast
/// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
/// @group color-system
$yiq-threshold: $yiq-contrasted-threshold;

/// Dark color for use in YIQ color contrast function.
/// @group color-system
$yiq-dark: $yiq-text-dark;

/// Light color for use in YIQ color contrast function.
/// @group color-system
$yiq-light: $yiq-text-light;


// Spacing
$spacer: $spacer;
$spacer-x: $spacer;
$spacer-y: $spacer;


// Fonts

/// Base font size across all components.
/// @group fonts
$font-size: $font-size-base;
$font-size-xs: .75 * $font-size-base;
$font-size-sm: $font-size-sm;
$font-size-lg: $font-size-lg;

/// Font family across all components.
/// @group fonts
$font-family: $font-family-base;

/// Font family for monospaced text. Used for styling the code.
/// @group fonts
$font-family-monospace: $font-family-monospace;

/// Line height used along with $font-size.
/// @group fonts
$line-height: $line-height-base;
$line-height-sm: $line-height-sm;
$line-height-lg: $line-height-lg;
$line-height-em: $line-height * 1em;


// Font weight
$font-weight-light: $font-weight-light;
$font-weight-normal: $font-weight-normal;
$font-weight-bold: $font-weight-bold;


// Headings
$h1-font-size: $h1-font-size;
$h2-font-size: $h2-font-size;
$h3-font-size: $h3-font-size;
$h4-font-size: $h4-font-size;
$h5-font-size: $h5-font-size;
$h6-font-size: $h6-font-size;

$h1-margin-bottom: $headings-margin-bottom;
$h2-margin-bottom: $headings-margin-bottom;
$h3-margin-bottom: $headings-margin-bottom;
$h4-margin-bottom: $headings-margin-bottom;
$h5-margin-bottom: $headings-margin-bottom;
$h6-margin-bottom: $headings-margin-bottom;

// Display
$display1-font-size: $display1-size;
$display2-font-size: $display2-size;
$display3-font-size: $display3-size;
$display4-font-size: $display4-size;

// Paragraph
$paragraph-margin-bottom: $paragraph-margin-bottom;


// Metrics
$padding-x: $spacer;
$padding-y: $spacer / 2;
$padding-x-sm: $padding-x / 2;
$padding-y-sm: $padding-y / 2;
$padding-x-lg: $padding-x * 1.5;
$padding-y-lg: $padding-y * 1.5;

/// Border radius for all components.
$border-radius: $border-radius;
$border-radius-sm: $border-radius-sm;
$border-radius-lg: $border-radius-lg;

$panel-padding-x: $card-spacer-x;
$panel-padding-y: $card-spacer-y;

$popup-padding-x: 0;
$popup-padding-y: $dropdown-padding-y;

$header-padding-x: $card-spacer-x;
$header-padding-y: $card-spacer-y;

$form-line-height: $input-btn-line-height;
$form-line-height-em: $form-line-height * 1em;

$button-padding-x: $btn-padding-x;
$button-padding-y: $btn-padding-y;
$button-padding-x-sm: $btn-padding-x-sm;
$button-padding-y-sm: $btn-padding-y-sm;
$button-calc-size: #{$form-line-height-em} + #{$button-padding-y * 2} + 2px;
$button-inner-calc-size: #{$form-line-height-em} + #{$button-padding-y * 2};

$input-border-width: $input-border-width;
$input-border-radius: $input-border-radius;
$input-padding-x: $input-padding-x;
$input-padding-y: $input-padding-y;
$input-padding-x-sm: $input-padding-x-sm;
$input-padding-y-sm: $input-padding-y-sm;
$input-calc-size: #{$form-line-height-em} + #{$input-padding-y * 2} + 2px;
$input-inner-calc-size: #{$form-line-height-em} + #{$input-padding-y * 2};

/// The horizontal padding of the container.
/// @group toolbar
$toolbar-padding-x: $table-cell-padding / 2;

/// The vertical padding of the container.
/// @group toolbar
$toolbar-padding-y: $table-cell-padding / 2;

$toolbar-button-padding-x: $button-padding-x;
$toolbar-button-padding-y: $button-padding-y;
$toolbar-inner-calc-size: #{$button-calc-size} + #{$toolbar-padding-y * 2};

$list-item-padding-x: $dropdown-item-padding-x;
$list-item-padding-y: 3px;

$nav-item-margin: 0;
$nav-item-padding-x: $padding-x;
$nav-item-padding-y: $padding-y;

$cell-padding-x: $table-cell-padding;
$cell-padding-y: $table-cell-padding;
$cell-padding: $table-cell-padding;
$cell-padding-sm: $table-cell-padding-sm;

$window-inner-padding-x: $modal-inner-padding;
$window-inner-padding-y: $modal-inner-padding;
$window-titlebar-padding-x: $modal-header-padding;
$window-titlebar-padding-y: $modal-header-padding;

$progressbar-height: $progress-height;

$zindex-popup: 1;
$zindex-window: 2;

$text-color: $body-color;
$bg-color: $body-bg;
$border-color: try-darken( $bg-color, 12% );

/// The background of the components' chrome area.
$base-bg: try-darken( $component-bg, 3% );
/// The text color of the components' chrome area.
$base-text: $component-color;
/// The border color of the components' chrome area.
$base-border: try-darken( $base-bg, 3% );
/// The gradient background of the components' chrome area.
$base-gradient: false;

/// The background of hovered items.
$hovered-bg: try-darken( $base-bg, 3% );
/// The text color of hovered items.
$hovered-text: $base-text;
/// The border color of hovered items.
$hovered-border: try-darken( $base-border, 12% );
/// The gradient background of hovered items.
$hovered-gradient: false;

/// The background of selected items.
$selected-bg: $accent;
/// The text color of selected items.
$selected-text: contrast-wcag( $selected-bg );
/// The border color of selected items.
$selected-border: try-darken( $selected-bg, 12% );
/// The gradient background of selected items.
$selected-gradient: false;

$transition: color .2s ease, background-color .2s ease, border-color .2s ease, box-shadow .2s ease;

$invalid-border: $error;

$gradient-transparent-to-black: rgba( black, 0), black;
$gradient-transparent-to-white: rgba( white, 0), white;
$gradient-black-to-transparent: black, rgba( black, 0);
$gradient-white-to-transparent: white, rgba( white, 0);

$gradient-rainbow: #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000;




// Component
$componet-bg: $component-bg;
$componet-text: $component-color;
$componet-border: $component-border-color;


// Widget
$widget-bg: $component-bg;
$widget-text: $component-color;
$widget-border: $component-border-color;


// Panel
$panel-text: $card-color;
$panel-bg: $card-bg;
$panel-border: $card-border-color;


// Popup
$popup-text: $dropdown-link-color;
$popup-bg: $dropdown-bg;
$popup-border: $dropdown-border-color;


// Header
$header-text: $card-cap-color;
$header-bg: $card-cap-bg;
$header-border: try-darken( $header-bg, 9 );
$header-gradient: false;

$header-hovered-text: $header-text;
$header-hovered-bg: try-darken( $header-bg, 3 );
$header-hovered-border: $header-border;
$header-hovered-gradient: false;

$header-selected-text: $selected-text;
$header-selected-bg: $selected-bg;
$header-selected-border: $selected-border;
$header-selected-gradient: false;


// Button

/// The background of the buttons.
/// @group buttons
$button-bg: $secondary;

/// The text color of the buttons.
/// @group buttons
$button-text: contrast-wcag( $button-bg, $gray-900 );

/// The border color of the buttons.
/// @group buttons
$button-border: $button-bg;

/// The background gradient of the buttons.
/// @group buttons
$button-gradient: false;
$button-shadow: false;

/// The background of hovered buttons.
/// @group buttons
$button-hovered-bg: try-darken( $button-bg, 7.5% );

/// The text color of hovered buttons.
/// @group buttons
$button-hovered-text: contrast-wcag( $button-hovered-bg, $gray-900 );

/// The border color of hovered buttons.
/// @group buttons
$button-hovered-border: try-darken( $button-border, 10% );

/// The background gradient of hovered buttons.
/// @group buttons
$button-hovered-gradient: false;
$button-hovered-shadow: false;

/// The background color of pressed buttons.
/// @group buttons
$button-pressed-bg:  try-darken( $button-bg, 10% );

/// The text color of pressed buttons.
/// @group buttons
$button-pressed-text: contrast-wcag( $button-pressed-bg, $gray-900 );

/// The border color of pressed buttons.
/// @group buttons
$button-pressed-border: try-darken( $button-border, 12.5% );

/// The background gradient of pressed buttons.
/// @group buttons
$button-pressed-gradient: false;
$button-pressed-shadow: false;

/// The shadow of focused buttons.
/// @group buttons
$button-focused-shadow: 0 0 0 3px rgba($button-border, .5);


// Primary button
$primary-button-bg: $accent;
$primary-button-text: contrast-wcag( $primary-button-bg );
$primary-button-border: $primary-button-bg;
$primary-button-gradient: false;

$primary-button-hovered-bg: try-darken( $primary-button-bg, 7.5% );
$primary-button-hovered-text: contrast-wcag( $primary-button-hovered-bg );
$primary-button-hovered-border: try-darken( $primary-button-border, 10% );
$primary-button-hovered-gradient: false;

$primary-button-pressed-bg: try-darken( $primary-button-bg, 10% );
$primary-button-pressed-text: contrast-wcag( $primary-button-pressed-bg );
$primary-button-pressed-border: try-darken( $primary-button-border, 12.5% );
$primary-button-pressed-gradient: false;

$primary-button-focused-shadow: 0 0 0 3px rgba($primary-button-border, .5);

$flat-button-hover-opacity: .08;
$flat-button-focused-opacity: false;
$flat-button-active-opacity: .16;
$flat-button-selected-opacity: .2;


// Toolbar
$toolbar-text: $header-text;
$toolbar-bg: $header-bg;
$toolbar-border: $header-border;
$toolbar-gradient: false;


// Input
$input-text: $input-color;
$input-bg: $input-bg;
$input-border: $input-border-color;
$input-shadow: none;

$input-hovered-text: $input-text;
$input-hovered-bg: $input-bg;
$input-hovered-border: $input-border;
$input-hovered-shadow: none;

$input-focused-text: $input-text;
$input-focused-bg: $input-bg;
$input-focused-border: try-lighten( $accent, 25 );
$input-focused-shadow: none;


// Link
$link-text: $link-color;
$link-hover-text: $link-hover-color;


// Node
$node-text: $base-text;
$node-bg: $base-bg;
$node-border: $base-border;
$node-gradient: false;

$node-hovered-text: $list-group-hover-color;
$node-hovered-bg: $list-group-hover-bg;
$node-hovered-border: $node-hovered-bg;
$node-hovered-gradient: false;

$node-selected-text: $selected-text;
$node-selected-bg: $selected-bg;
$node-selected-border: $selected-border;
$node-selected-gradient: false;


// List item
$list-item-text: $panel-text;
$list-item-bg: $panel-bg;

$list-item-hovered-text: $dropdown-link-hover-color;
$list-item-hovered-bg: $dropdown-link-hover-bg;

$list-item-selected-text: $dropdown-link-active-color;
$list-item-selected-bg: $dropdown-link-active-bg;

$list-item-focused-shadow: inset 0 0 0 3px rgba( $list-item-text, .15 );


// Tab
$tab-item-text: $link-text;
$tab-item-bg: transparent;
$tab-item-border: $nav-tabs-border-color;

$tab-item-hovered-text: $link-hover-text;
$tab-item-hovered-bg: transparent;
$tab-item-hovered-border: $nav-tabs-link-hover-border-color;

$tab-item-selected-text: $nav-tabs-link-active-color;
$tab-item-selected-bg: $nav-tabs-link-active-bg;
$tab-item-selected-border: $nav-tabs-link-active-border-color;


// Grid
$grid-bg: $white;
$grid-text: $table-color;
$grid-border: $white;

$grid-alt-text: $grid-text;
$grid-alt-bg: rgba( $primary, .05);
$grid-alt-border: $grid-border;

$grid-hovered-text: $grid-text;
$grid-hovered-bg: transparent;
$grid-hovered-border: $grid-border;

$grid-header-bg: $white;
$grid-header-text: $header-text;
$grid-header-border: $white;

$grid-sorted-bg: $white;

//Pager
$pager-text: $pagination-color;
$pager-bg: $pagination-bg;
$pager-border: $pagination-border-color;

$pager-hover-text: $pagination-hover-color;
$pager-hover-bg: $pagination-hover-bg;
$pager-hover-border: $pagination-hover-border-color;

$pager-selected-text: $pagination-active-color;
$pager-selected-bg: $pagination-active-bg;
$pager-selected-border: $pagination-active-border-color;

// Notification
$notification-info-bg: color-level($info, 10);
$notification-info-text: color-level($info, -6);
$notification-info-border: color-level($info, 9);
$notification-success-bg: color-level($success, 10);
$notification-success-text: color-level($success, -6);
$notification-success-border: color-level($success, 9);
$notification-warning-bg: color-level($warning, 10);
$notification-warning-text: color-level($warning, -6);
$notification-warning-border: color-level($warning, 9);
$notification-error-bg: color-level($error, 10);
$notification-error-text: color-level($error, -6);
$notification-error-border: color-level($error, 9);


// Window
$window-text: $modal-content-color;
$window-bg: $modal-content-bg;
$window-border: $modal-content-border-color;

$window-titlebar-text: $modal-header-color;
$window-titlebar-bg: $modal-header-bg;
$window-titlebar-border: $modal-header-border-color;


// Progress
$progress-bg: $progress-bg;
$progress-text: $progress-color;
$progress-border: transparent;
$progress-gradient: false;

$progress-fill-bg:  $progress-bar-bg;
$progress-fill-text: $progress-bar-color;
$progress-fill-border: transparent;
$progress-fill-gradient: false;


// Chart
/// The color of the first series.
/// @group charts
$series-a: #0275d8;

/// The color of the second series.
/// @group charts
$series-b: #5bc0de;

/// The color of the third series.
/// @group charts
$series-c: #5cb85c;

/// The color of the fourth series.
/// @group charts
$series-d: #f0ad4e;

/// The color of the fifth series.
/// @group charts
$series-e: #e67d4a;

/// The color of the sixth series.
/// @group charts
$series-f: #d9534f;

$chart-font-size: $font-size;
$chart-font-size-sm: 11px;
$chart-font-size-md: 12px;
$chart-font-size-lg: 16px;

$chart-inactive: rgba( $text-color, .5 );

/// The color of the Chart grid lines (major).
/// @group charts
$chart-major-lines: rgba(0, 0, 0, .08);

/// The color of the Chart grid lines (minor).
/// @group charts
$chart-minor-lines: rgba(0, 0, 0, .04);

$chart-area-opacity: .8;

// Icons
$icon-size: 16px;
$icon-size-lg: 32px;
$icon-spacing: ($spacer / 2);

// Disabled mixin variables
$disabled-filter: grayscale(.1);
$disabled-opacity: .65;

// Pager
$pager-padding-x: $toolbar-padding-x;
$pager-padding-y: $toolbar-padding-y;


// Card
$card-padding-x: $card-spacer-x;
$card-padding-y: $card-spacer-y;
$card-border-width: 1px;
$card-border-radius: $card-border-radius;
$card-box-shadow: false;

$card-deck-gap: $card-deck-margin;

$card-bg: $card-bg;
$card-text: $panel-text;
$card-border: $card-border-color;

$card-header-padding-x: $card-padding-x;
$card-header-padding-y: $card-padding-y;
$card-header-border-width: $card-border-width;
$card-header-bg: $card-cap-bg;
$card-header-text: $card-text;
$card-header-border: $card-border-color;

$card-body-padding-x: $card-padding-x;
$card-body-padding-y: $card-padding-y;

$card-title-margin-bottom: $h5-margin-bottom;
$card-subtitle-margin-bottom: $h6-margin-bottom;

$card-actions-padding-x: $card-padding-x;
$card-actions-padding-y: ($card-padding-y - $button-padding-y);
$card-actions-border-width: 1px;


// Chat
$chat-padding-x: 16px;
$chat-padding-y: 16px;

$chat-item-spacing-x: 8px;
$chat-item-spacing-y: 16px;

$chat-message-list-padding-x: $chat-padding-x;
$chat-message-list-padding-y: $chat-padding-y;
$chat-message-list-spacing: $chat-item-spacing-y;

$chat-bubble-padding-x: 16px;
$chat-bubble-padding-y: 8px;
$chat-bubble-spacing: 2px;
$chat-bubble-line-height: 1.25;

$chat-bubble-border-radius: 12px;
$chat-bubble-border-radius-sm: 2px;

$chat-avatar-size: 32px;
$chat-avatar-spacing: $chat-item-spacing-x;

$chat-message-box-padding-x: $chat-padding-x;
$chat-message-box-padding-y: 10px;

$chat-quick-reply-padding-x: 12px;
$chat-quick-reply-padding-y: 8px;
$chat-quick-reply-spacing: 8px;
$chat-quick-reply-line-height: $chat-bubble-line-height;


$chat-bg: $popup-bg;
$chat-text: $popup-text;
$chat-border: $popup-border;

$chat-bubble-bg: $button-bg;
$chat-bubble-text: $button-text;
$chat-bubble-border: $chat-bubble-bg;

$chat-alt-bubble-bg: $accent;
$chat-alt-bubble-text: $accent-contrast;
$chat-alt-bubble-border: $chat-alt-bubble-bg;


// Config maps
$typography-config: ();
// sass-lint:disable indentation
$typography-config: map-merge((
    display-1: font-map($display1-font-size, $display-line-height, $display1-weight, inherit),
    display-2: font-map($display2-font-size, $display-line-height, $display2-weight, inherit),
    display-3: font-map($display3-font-size, $display-line-height, $display3-weight, inherit),
    display-4: font-map($display4-font-size, $display-line-height, $display4-weight, inherit),
    h1: font-map($h1-font-size, normal, $headings-font-family, $headings-font-family),
    h2: font-map($h2-font-size, normal, $headings-font-family, $headings-font-family),
    h3: font-map($h3-font-size, normal, $headings-font-family, $headings-font-family),
    h4: font-map($h4-font-size, normal, $headings-font-family, $headings-font-family),
    h5: font-map($h5-font-size, normal, $headings-font-family, $headings-font-family),
    h6: font-map($h6-font-size, normal, $headings-font-family, $headings-font-family),
    body: font-map($font-size-base, $line-height-base, $font-weight-base, $font-family-base)
), $typography-config);
$typography-config: map-merge((
    card-title: map-get($typography-config, h5),
    card-subtitle: map-get($typography-config, h6)
), $typography-config);
// sass-lint:enable indentation

// import any default values from kendo-theme-default
@import "@progress/kendo-theme-bootstrap/modules/@progress/kendo-theme-default/scss/variables";
