﻿.app__header {
    &__container {
        grid-area: header;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.4rem 0.2rem 1.4rem;
        background-color: $white;
        box-shadow: 0 0 12px rgba(0,0,0,0.4), 0 0 26px rgba(0,0,0,0.12);
        z-index: 200;

        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
        }


        &__left,
        &__right,
        &__center {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &__button {
        background: $white;
        border: none;
        border-radius: 0.8rem;
        transition: background-color 120ms ease-in-out, color 120ms ease-in-out;

        &:hover {
            background: rgba($primary, 0.15);
        }

        &:active {
            background: rgba($primary, 0.15);
        }

        &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px rgba($primary, .5);
            z-index: 200;
        }

        &__icon {
            border-radius: 4.8rem;
            margin: 0 0.4rem;

            &:hover {
                color: rgba($primary, 0.15);
            }
        }
    }

    .dropdown-menu {
        width: -webkit-fill-available;
    }

    .dynamic_hub_logo {
        height: 36px !important;
        margin-right: 5px !important;
    }
}
