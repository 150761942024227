@include exports("dialog/layout") {

    .k-dialog-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;

        .k-dialog {
            position: relative;
        }
    }

    .k-dialog {
        padding: 0;
        position: fixed;
        box-sizing: border-box;

        // Centered
        &.k-dialog-centered {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
    }


    // Titlebar
    .k-dialog-titlebar {}
    .k-dialog-title {}

    .k-dialog-close {
        align-self: flex-end;
    }


    // Actions
    .k-dialog-actions {}
    .k-dialog-action {}


    // Content
    .k-dialog-content {}


    // Buttons
    .k-dialog-buttongroup {
        border-width: 1px 0 0;
        border-style: solid;
        border-color: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        flex: 0 0 auto;

        .k-button {
            // Kendo UI for jQuery sets inline width
            width: auto !important; // sass-lint:disable-line no-important
            max-width: 100%;
            display: inline-block; // needed for ellipsis overflow
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}
