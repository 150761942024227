$calendar-header-shadow: none !default;
$calendar-weekend-bg: transparent !default;
$calendar-week-number-bg: transparent !default;
$calendar-range-selection: lighten($accent, 40%) !default;
$calendar-today-style: box !default;
$calendar-navigation-bg: $header-bg !default;
$calendar-navigation-border: $header-border !default;
$calendar-focus-shadow: inset $button-focused-shadow !default;
$calendar-selected-focus-shadow: inset 0 0 0 2px rgba($primary-button-pressed-border, .5) !default;

@import "../../modules/@progress/kendo-theme-default/scss/calendar/theme";

@include exports("calendar/theme/bootstrap") {

    .k-calendar-monthview .k-content th {
        font-size: $font-size-xs;
        color: $header-text;
    }
}
