@include exports("treelist/layout") {
    // Base
    .k-treelist {
        &.k-display-block.k-grid-lockedcolumns {
            display: block;
        }

        .k-status {
            padding: .4em .6em;
            line-height: 1.6em;

            .k-loading {
                vertical-align: baseline;
                margin-right: 5px;
            }
        }

        tr.k-hidden {
            display: none;
        }

        .k-treelist-dragging,
        .k-treelist-dragging .k-state-hover {
            cursor: default;
        }

        .k-drop-hint {
            position: absolute;
            z-index: 10000;
            visibility: hidden;
            width: 80px;
            height: 5px;
            margin-top: -3px;
            background-color: transparent;
            background-repeat: no-repeat;
        }
    }

    .k-drag-separator {
        display: inline-block;
        border-right: 1px solid;
        height: 1em;
        vertical-align: top;
        margin: 0 .5em;
    }
}
