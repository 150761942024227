@include exports("listbox/theme") {

    .k-listbox {

        .k-list-scroller {
            @include appearance( panel );
        }
        .k-drop-hint {
            border-top-color: $accent;
        }
    }

    .k-item.k-drag-clue {
        background-color: $list-item-selected-bg;
        color: $list-item-selected-text;
    }

}