﻿#fileVaultPage {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}

#fileVaultPage {
    font-family: Nunito;
    background-color: white;
}

#fileVaultList.k-grid {
    border: 0px solid;
    box-shadow: none;
}

#fileVaultList.k-grid .k-grid-content .k-alt {
    background-color: transparent;
}

.fileHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.fileColHeader  {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.64px
}

.fileActionLink {
    color: rgba(22, 109, 255, 1);
}

.fileGroupName {
    background: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    line-height: 18px;
}

#fileVaultPage .k-grid table tbody tr td {
    border-bottom: 1px solid #E2E2E2;
}

#fileVaultTap li.active a {
    border-bottom: 2px solid #166DFF;
    background-color: #ECF1F8;
    color: #0061F1;
}

#fileVaultTap.nav-tabs .nav-link {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
}

.scrollableCard {
    overflow-y: auto;
    max-height: 425px;
}

.word_file {
    color: darkblue;
}

.excel_file {
    color: green;
}

.pdf_file {
    color: red;
}

.video_file {
    color:blueviolet;
}

.ppt_file {
    color: maroon;
}

.zip_file {
    color: dimgray;
}
