$slider-track-background-color: darken($header-bg, 7%) !default;

@include exports("mediaplayer/theme") {

    // Theme
    .k-mediaplayer {}


    // Title bar
    .k-mediaplayer-titlebar {
        color: $panel-bg;
        background-image: linear-gradient( rgba( $panel-text, .7 ), rgba( $panel-text, 0 ) );
    }


    .k-mediaplayer-toolbar {
        background-color: rgba($toolbar-bg, .85);

        .k-slider-track {
            background: $slider-track-background-color;
        }
    }

}
