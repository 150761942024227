@include exports( "common/utils/flex" ) {

    // Flex wrap
    .k-flex-wrap                { flex-wrap: wrap; }
    .k-flex-nowrap              { flex-wrap: nowrap; }
    .k-flex-wrap-reverse        { flex-wrap: wrap-reverse; }


    // Flex, shrink grow
    .k-flex                     { flex: 1 1 0; }
    .k-flex-auto                { flex: 1 1 auto; }
    .k-no-flex                  { flex: 0 0 0; }
    .k-no-flex-auto             { flex: 0 0 auto; }
    .k-flex-grow                { flex-grow: 1; }
    .k-no-flex-grow             { flex-grow: 0; }
    .k-flex-shrink              { flex-shrink: 1; }
    .k-no-flex-shrink           { flex-shrink: 0; }


    // Flex align
    .k-align-items-start        { align-items: flex-start; }
    .k-align-items-end          { align-items: flex-end; }
    .k-align-items-center       { align-items: center; }
    .k-align-items-stretch      { align-items: stretch; }
    .k-align-items-baseline     { align-items: baseline; }

    .k-align-content-start      { align-content: flex-start; }
    .k-align-content-end        { align-content: flex-end; }
    .k-align-content-center     { align-content: center; }
    .k-align-content-stretch    { align-content: stretch; }
    .k-align-content-baseline   { align-content: baseline; }

    .k-align-self-start         { align-self: flex-start; }
    .k-align-self-end           { align-self: flex-end; }
    .k-align-self-center        { align-self: center; }
    .k-align-self-stretch       { align-self: stretch; }
    .k-align-self-baseline      { align-self: baseline; }


    // Flex justify
    .k-justify-content-start    { justify-content: flex-start; }
    .k-justify-content-end      { justify-content: flex-end; }
    .k-justify-content-center   { justify-content: center; }
    .k-justify-content-between  { justify-content: space-between; }
    .k-justify-content-around   { justify-content: space-around; }
    .k-justify-content-evenly   { justify-content: space-evenly; }

}
