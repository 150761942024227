@include exports("datetime/layout") {
    $timepicker-header-height: 2em;
    $time-highlight-size: 1px;
    $datetimepicker-button-border-width: $input-border-width !default;

    // Datepicker
    .k-datepicker,
    .k-datetimepicker,
    .k-timepicker {

        .k-i-warning {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            overflow: visible;
        }

        .k-state-invalid {
            .k-i-warning {
                display: inline-block;
            }
        }

        .k-dateinput {
            width: 100%;
            flex: 1 1 0%;
            margin: 0;
        }

        .k-dateinput-wrap {
            border: 0;
            @include border-left-radius-only();
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                right: auto;
            }
        }
    }

    .k-datepicker,
    .k-timepicker {

        .k-i-warning {
            right: calc( #{ button-inner-size( 1 ) } + #{ $input-padding-x-sm });
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                left: calc( #{ button-inner-size( 1 ) } + #{ $input-padding-x-sm });
            }
        }
    }

    .k-datetimepicker {

        .k-i-warning {
            right: calc( #{ button-inner-size( 2 ) } + #{ $input-padding-x-sm });
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                left: calc( #{ button-inner-size( 2 ) } + #{ $input-padding-x-sm });
            }
        }
    }

    // Timepicker
    .k-timepicker {}

    // Datetimepicker
    .k-datetimepicker {

        .k-select {
            padding: 0;
            border-left-width: 0;
            align-items: stretch;
        }
        .k-link {
            padding: $button-padding-y;
            border-width: 0 0 0 $datetimepicker-button-border-width;
            border-style: solid;
            border-color: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }
    }


    // Dateinput
    .k-dateinput {
        position: relative;
        border-width: 0;

        .k-i-warning {
            display: none;
            position: absolute;
            right: $padding-x;
            top: 50%;
            transform: translateY(-50%);
            overflow: visible;
        }

        &.k-state-invalid .k-i-warning {
            display: inline-block;
        }

        .k-rtl &,
        &[dir='rtl'] {
            .k-i-warning {
                left: $padding-x;
                right: auto;
            }
        }

    }

    // Infinite timepicker
    // Header
    .k-time-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2 * $padding-y $padding-x * 2;
        line-height: $timepicker-header-height;

        .k-time-now {
            border-width: 0;
            background: transparent;
            line-height: inherit;
        }
    }

    $time-list-padding: $padding-y * 5;
    $time-list-focus-size: 2px;
    $time-list-width: 5em !default;
    $time-list-height: 200px !default;

    // Content
    .k-time-list-wrapper {
        display: inline-block;
        overflow: hidden;
        box-sizing: content-box;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        padding: $time-list-padding 0;
        text-align: center;
        width: $time-list-width;
        height: $time-list-height;

        .k-title {
            display: block;
            text-align: center;
            font-size: $font-size-xs;
            position: absolute;
            text-transform: capitalize;
            font-weight: bold;
            min-width: 100%;
            height: 1.5em;
            line-height: 1.5em;
            margin-top: -$time-list-padding;
            background: transparent;
        }

        &.k-state-focused {
            &::before,
            &::after {
                display: block;
                content: " ";
                position: absolute;
                width: 100%;
                left: 0;
                pointer-events: none;
                height: calc(50% - 1em);
                box-sizing: border-box;
                border-style: solid;
            }

            &::before {
                top: 0;
                border-width: $time-list-focus-size $time-list-focus-size 0;
            }

            &::after {
                bottom: 0;
                border-width: 0 $time-list-focus-size $time-list-focus-size;
            }
        }
    }

    .k-time-container {
        position: absolute;
        display: block;
        overflow-x: hidden;
        overflow-y: scroll;
        line-height: $line-height;
        left: 0;
        right: 0;
        top: $time-list-padding;
        bottom: $time-list-padding;

        @include hide-scrollbar('right');

        > ul {
            height: auto;
            width: $time-list-width;
        }
    }

    .k-time-list-container {
        display: flex;
        position: relative;
    }

    .k-time-list {
        position: absolute;
        display: flex;
        z-index: 10;
        outline: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        &::before,
        &::after {
            display: block;
            position: absolute;
            content: " ";
            height: 0;
            line-height: 0;
            z-index: 1;
            width: 200%;
            left: -50%;
        }

        &::before { top: 0; }
        &::after { bottom: 0;}
    }

    .k-time-list .k-item {
        padding: $list-item-padding-y $list-item-padding-x;
        min-height: add-two($line-height-em, 2px);
        line-height: add-two($line-height-em, 2px);
    }

    .k-time-highlight {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
        height: button-inner-size();
        z-index: 1;
        border-width: $time-highlight-size 0;
        border-style: solid;
        border-radius: .1px; // workaround for https://bugs.webkit.org/show_bug.cgi?id=175117
    }

    .k-time-container .k-scrollable-placeholder {
        position: absolute;
        width: 1px;
        top: 0;
        right: 0;
    }

    .k-time-separator {
        display: inline-flex;
        align-self: center;
        justify-content: center;
        height: 100%;
        z-index: 11;
    }
}
