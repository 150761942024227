$menu-separator-spacer-x: 2px !default;
$menu-separator-spacer-y: $menu-separator-spacer-x !default;
$image-spacing: 8px !default;

@include exports("menu/layout") {

    // Base
    .k-menu {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        position: relative;
        cursor: default;

        // Item
        .k-item {
            border-width: 0;
            border-style: solid;
            border-color: inherit;
            display: flex;
            align-items: stretch;
            position: relative;
            user-select: none;
            flex-shrink: 0;
            outline: 0;
        }

        // Link
        .k-item > .k-link {
            cursor: pointer;
            padding: $nav-item-padding-y $nav-item-padding-x;
            line-height: $line-height;
            color: inherit;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            white-space: nowrap;
        }
    }

    .k-menu-link {

        > .k-icon,
        > .k-image,
        > .k-sprite {
            margin-right: $image-spacing;
        }
    }

    .k-menu-expand-arrow {
        &.k-i-arrow-60-down {
            margin-left: $icon-spacing;
            margin-right: -$icon-spacing;
        }

        &.k-i-arrow-60-left,
        &.k-i-arrow-60-right {
            margin: (-$icon-size / 2) 0 0;
            position: absolute;
            top: 50%;
        }
        &.k-i-arrow-60-right {
            right: $icon-spacing;
        }
        &.k-i-arrow-60-left {
            left: $icon-spacing;
        }
    }


    // Sub
    .k-menu-group {
        margin: 0;
        padding: $popup-padding-y $popup-padding-x;
        white-space: nowrap;
        list-style: none;
        display: none;
        position: absolute;

        .k-menu-popup & {
            position: relative;
            display: block;
        }
    }


    // Orientation
    .k-menu-horizontal {

        > .k-item  + .k-item {
            margin-left: $nav-item-margin;
        }

        > .k-separator + .k-item {
            margin-left: 0;
        }

        > .k-item.k-separator {
            @extend %hr-vertical;
            margin: 0 $menu-separator-spacer-x;
        }
    }
    .k-menu-vertical {

        > .k-item + .k-item {
            margin-top: $nav-item-margin;
        }

    }
    .k-menu-group,
    .k-menu-vertical {
        flex-direction: column;

        .k-item {
            display: block;
            border-color: inherit;
        }

        .k-item > .k-link {
            line-height: $line-height;
            padding: $list-item-padding-y $list-item-padding-x;
            padding-right: add-two( (2 * $list-item-padding-x), $icon-size);
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
        }

        .k-item.k-separator {
            @extend %hr;
            margin: $menu-separator-spacer-y 0;
        }
    }


    // Context menu
    .k-popups-wrapper {
        position: relative;
        border: 0;
        margin: 0;
        padding: 0;
    }
    .k-context-menu {
        margin: 0;
        border-width: 1px;
        border-style: solid;
        box-sizing: content-box;

        &.k-menu-horizontal {
            padding: $popup-padding-x $popup-padding-y;
        }

        & > .k-item + .k-item:not(.k-separator) {
            margin-left: 0;
        }
    }
    .k-animation-container .k-context-menu.k-menu-horizontal {
        // kendo-jquery adds `display: block` via js and we need to override it.
        display: flex !important; // sass-lint:disable-line no-important
    }


    // Scrolling
    .k-menu-scroll-wrapper {
        margin: 0;
        padding: 0;
        border: 0;
        position: relative;

        .k-menu {
            overflow: hidden;
        }
    }
    .k-menu-scroll-button {
        @include border-radius( 0 );
        padding: 0;
        border-width: 0;
        border-color: inherit;
        color: inherit;
        background: inherit;
        background-clip: border-box;
        position: absolute;

        &.k-scroll-left {
            top: 0;
            left: 0;
            height: 100%;
            width: 16px;
            border-right-width: 1px;
        }
        &.k-scroll-right {
            top: 0;
            right: 0;
            height: 100%;
            width: 16px;
            border-left-width: 1px;
        }
        &.k-scroll-up {
            top: 0;
            left: 0;
            width: 100%;
            height: 16px;
            border-bottom-width: 1px;
        }
        &.k-scroll-down {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 16px;
            border-top-width: 1px;
        }
    }


    // RTL

    .k-rtl,
    [dir="rtl"] {

        .k-menu-link {
            > .k-icon,
            > .k-image,
            > .k-sprite {
                margin-left: $image-spacing;
                margin-right: 0;
            }

            .k-icon.k-menu-expand-arrow {
                margin-left: 0;
            }
        }

        .k-menu-expand-arrow {
            &.k-i-arrow-60-down {
                margin-left: -$icon-spacing;
                margin-right: $icon-spacing;
            }
        }

        .k-menu-horizontal {

            & > .k-item:first-of-type {
                margin-left: ( $nav-item-margin / 2 );
                margin-right: 0;
            }
        }

        .k-menu-group,
        .k-menu-vertical {

            .k-link {
                padding-right: $list-item-padding-x;
                padding-left: add-two( (2 * $list-item-padding-x), $icon-size);
            }
        }
    }

}
