@include exports( "common/panel" ) {

    .k-block,
    .k-panel {
        @extend %text-selection !optional;
        @include appearance( widget );
        @include border-radius();
        padding: $panel-padding-y $panel-padding-x;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;

        > .k-header {
            @include appearance( header );
            @include border-top-radius();
            margin: #{-$panel-padding-y} #{-$panel-padding-x} $panel-padding-y;
            padding: $header-padding-y $header-padding-x;
        }

    }

}
