@import '../common/icons';
@import '../button/layout';

$scrollview-pager-offset: 20px !default;
$scrollview-pager-item-spacing: 20px !default;

@include exports('scrollview/layout') {
    $scrollview-page-button-size: 8px;
    $scrollview-arrow-icon-size: 4.5em;

    .k-scrollview-wrap {
        position: relative;
        overflow: hidden;
        outline: 0;
        display: block;
    }

    .k-scrollview {
        list-style-type: none;
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        cursor: default;

        img {
            user-select: none;
        }

        & > li {
            display: inline-block;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .k-pages,
    .k-scrollview-pageable {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        list-style: none;
        position: absolute;
        left: 0;
        bottom: $scrollview-pager-offset;
        pointer-events: none;
    }
    .k-pages > li,
    .k-scrollview-pageable > li.k-button {
        margin: 0 $scrollview-pager-item-spacing;
        padding: 0;
        width: $scrollview-page-button-size;
        height: $scrollview-page-button-size;
        box-sizing: content-box;
        vertical-align: middle;
        display: inline-block;
        flex: 0 0 $scrollview-page-button-size;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: all;
    }

    .k-scrollview-next,
    .k-scrollview-prev {
        display: table;
        position: absolute;
        padding: 0;
        height: 60%;
        top: 20%;
        text-decoration: none;
        user-select: none;
        cursor: pointer;
        overflow: hidden;
        -webkit-tap-highlight-color: $rgba-transparent;
    }

    .k-scrollview-prev {
        left: 0;
    }

    .k-scrollview-next {
        right: 0;
    }

    .k-scrollview-next span,
    .k-scrollview-prev span {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        font-size: $scrollview-arrow-icon-size;
        font-weight: normal;
    }

    .k-scrollview-elements {
        width: 100%;
    }

    @supports (-webkit-user-select: none) {
        div.k-scrollview-wrap ul.k-scrollview li > *  {
            pointer-events: auto;
        }
    }

    @supports (not (-webkit-user-select: none)) {
        div.k-scrollview-wrap ul.k-scrollview li > *  {
            pointer-events: none;
        }
    }

    //Mobile version
    .km-scrollview {
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        width: 100%;

        & > div > * {
            -webkit-transform: translatez(0);
        }

        & > div > [data-role="page"] {
            vertical-align: top;
            display: inline-block;
            min-height: 1px;
        }

        .km-virtual-page {
            min-height: 1px;
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
        }
    }

    .k-ff18 .km-scrollview > div,
    .k-ff19 .km-scrollview > div,
    .k-ff20 .km-scrollview > div,
    .k-ff21 .km-scrollview > div {
        width: 0;
    }

    .km-pages {
        padding: $padding-y 0 0 0;
        position: static;
    }
}
