﻿.input-group--floating {
    position: relative;

    .form-control {
        //height: 3.25rem;
        padding-left: 1rem;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-width: 2px;

        &:valid + label {
            opacity: 1;
            transform: translateY(0);
        }

        &:valid {
            padding-top: 1rem;
            padding-bottom: 0;
        }

        &:focus + label + span {
            color: $primary;
        }

        &[readonly] {
            background-color: inherit;
            cursor: initial;
        }
    }

    .input-label {
        color: #757575;
        position: absolute;
        //z-index: 99999;
        left: 1rem;
        transition: all .25s cubic-bezier(.645, .045, .355, 1);
        transform: translateY(6px);
        opacity: 0;
        top: 0.25rem;
        font-size: 0.75em;
        cursor: text;
        pointer-events: none;
    }

    .input-icon {
        font-size: 1.25rem;
        position: absolute;
        color: #c7c7c7;
        z-index: 5;
        font-style: normal;
        transition: .25s cubic-bezier(.645, .045, .355, 1) color;
        left: 1rem;
        top: 1rem;
    }
}

.icon-input-group--floating {
    position: relative;

    .form-control {
        height: 3.25rem;
        padding-left: 3rem;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-width: 2px;

        &:valid + label {
            opacity: 1;
            transform: translateY(0);
        }

        &:valid {
            padding-top: 1rem;
            padding-bottom: 0;
        }

        &:focus + label + span {
            color: $primary;
        }
    }

    .input-label {
        color: #757575;
        position: absolute;
        z-index: 99999;
        left: 3rem;
        transition: all .25s cubic-bezier(.645, .045, .355, 1);
        transform: translateY(6px);
        opacity: 0;
        top: 0.25rem;
        font-size: 0.75em;
        cursor: text;
        pointer-events: none;
    }

    .input-icon {
        font-size: 1.25rem;
        position: absolute;
        color: #c7c7c7;
        z-index: 5;
        font-style: normal;
        transition: .25s cubic-bezier(.645, .045, .355, 1) color;
        left: 1rem;
        top: 1rem;
    }
}


//search bar component css.
.cbord-search-input-bg {
    color: rgba(0,0,0,0.87);
    background-color: #f1f1f1;
    box-shadow: none;
    border: hidden
}

.cbord-search-input-bg:focus {
    box-shadow: none;
    border: 0.5px solid #166DFF;
    background-color: #f1f1f1;
}

.has-search .form-control {
    padding-left: 2.375rem;
    padding-right: 1.875rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: rgb(0, 0, 0);
}

.has-search .form-control-feedback-close {
    position: absolute;
    z-index: 2;
    display: block;
    top: 0.0rem;
    right: 5%;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    cursor: pointer;
    color: rgb(0, 0, 0);
    visibility: hidden;
}


div.label-overlapped {
    display: inline-block;
    left: 5px;
    position: relative;
    top: 5px;
    background: white;
    z-index: 1;

    label {
        top: 5px;
        position: relative;
        padding: 0 5px;
    }
}
