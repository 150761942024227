@include exports("sreadsheet/theme") {

    // Spreadsheet
    .k-spreadsheet {}


    // Row / column headers
    .k-spreadsheet-top-corner {
        @include appearance( header );
    }
    .k-spreadsheet-row-header,
    .k-spreadsheet-column-header {
        @include appearance( header );
    }


    // Formula bar
    .k-spreadsheet-formula-list {}
    .k-syntax-ref { color: #ff8822; }
    .k-syntax-num { color: #0099ff; }
    .k-syntax-func { font-weight: bold; }
    .k-syntax-str { color: #38b714; }
    .k-syntax-error { color: red; }
    .k-syntax-bool { color: #a9169c; }
    .k-syntax-startexp { font-weight: bold; }
    .k-syntax-paren-match { background-color: #caf200; }


    // Cell editor
    .k-spreadsheet-cell-editor {
        color: $panel-text;
        background-color: $panel-bg;
    }


    // Selection
    .k-spreadsheet .k-selection-full,
    .k-spreadsheet .k-selection-partial {
        border-color: rgba( $header-selected-border, .25 );
        background-color: rgba( $header-selected-bg, .25 );
    }
    .k-spreadsheet-selection {
        border-color: $selected-bg;
        background-color: rgba( $selected-bg, .25 );
        box-shadow: inset 0 0 0 1px $selected-bg;
    }

    .k-spreadsheet .k-single-selection::after {
        border-color: $panel-bg;
        background-color: $selected-bg;
    }
    .k-spreadsheet-active-cell {
        box-shadow: inset 0 0 0 1px $selected-bg;
        background-color: $panel-bg;

        &.k-right {
            box-shadow: inset 0 0 0 1px $selected-bg, inset -1px 0 0 1px $selected-bg;
        }

        &.k-bottom {
            box-shadow: inset 0 0 0 1px $selected-bg, inset 0 -1px 0 1px $selected-bg;
        }

        &.k-bottom.k-right {
            box-shadow: inset 0 0 0 1px $selected-bg, inset -1px -1px 0 1px $selected-bg;
        }
    }

    .k-spreadsheet .k-auto-fill {
        border-color: $selected-bg;
        background-color: rgba( $selected-bg, .25 );
        box-shadow: inset 0 0 0 1px $selected-bg;
    }
    .k-spreadsheet .k-auto-fill-punch {
        background-color: rgba( $panel-bg, .5 );
    }

}




@include exports( "spreadsheet/filter/theme" ) {

    // Filter button
    .k-spreadsheet .k-filter-range {
        border-color: $accent;
    }
    .k-spreadsheet-filter {
        @include appearance( button );
        box-shadow: inset 0 0 0 1px $button-border;

        &.k-state-active {
            @include appearance( pressed-button );
        }

        &:hover {
            @include appearance( hovered-button );
        }
    }


    // Filter menu
    .k-spreadsheet-filter-menu {

        .k-spreadsheet-value-treeview-wrapper {
            @include appearance( panel );
        }
    }

}




@include exports( "spreadsheet/tools/theme" ) {

    .k-spreadsheet-popup {

        // Buttons
        .k-button {}

        .k-button:hover,
        .k-button.k-state-hover {
            @include appearance( hovered-list-item );
        }
        .k-button:active,
        .k-button.k-state-active,
        .k-button.k-state-selected {
            @include appearance( selected-list-item );
        }

    }

}