@include exports( "common/dragdrop/dragclue" ) {

    // Layout
    .k-drag-clue {
        padding: $padding-y-sm $padding-x-sm;
        border-width: 1px;
        border-style: solid;
        font-size: $font-size-sm;
        white-space: nowrap;
        overflow: hidden;
        cursor: default;
    }
    .k-drag-status {
        margin-right: .4ex;
    }


    // Theme
    .k-drag-clue {
        @include appearance( selected-node );
    }

}


@include exports( "common/dragdrop/reordercue" ) {

    // Layout
    .k-reorder-cue {
        position: absolute;

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 0;
            border: 3px solid transparent;
            position: absolute;
            transform: translateX(-50%);
        }

        &::before {
            border-bottom-width: 0;
            border-top-color: currentColor;
            top: -4px;
        }
        &::after {
            border-top-width: 0;
            border-bottom-color: currentColor;
            bottom: -4px;
        }
    }

}
