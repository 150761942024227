$upload-disabled-text: darken($widget-bg, 27%) !default;
$upload-border: $border-color !default;
$upload-error-text: $error !default;

@include exports('upload/theme') {

    $upload-bg: $header-bg !default;
    $upload-files-bg: $widget-bg !default;
    $upload-progress-text: $base-text !default;
    $upload-progress-bg: $info !default;
    $upload-error-bg: $error !default;
    $upload-error-border: lighten($error, 29%) !default;
    $upload-success-text: $success !default;
    $upload-success-bg: $success !default;
    $upload-disabled-border: darken($widget-bg, 27%) !default;
    $upload-focused-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;

    .k-upload {
        background-color: $upload-bg;
        border-color: $upload-border;
        background-clip: padding-box;

        .k-ie11 &,
        .k-edge12 &,
        .k-edge13 & {
            background-clip: border-box;
        }

        .k-upload-files,
        .k-upload-selected {
            border-color: $upload-border;
        }

        .k-file {
            background-color: $upload-files-bg;
            border-color: $upload-border;
            outline: none;

            &.k-state-focused {
                box-shadow: inset $upload-focused-shadow;
            }

            .k-upload-action {
                opacity: .6;
                color: inherit;
                background: none;
                border-width: 0;
                box-shadow: none;

                &:hover {
                    opacity: 1;
                }

                &.k-state-focused {
                    box-shadow: $upload-focused-shadow;
                }
            }

            .k-upload-pct {
                opacity: .65;
            }

        }

        .k-file-progress {
            color: $upload-progress-text;
        }

        .k-file-progress .k-progress {
            background-color: $upload-progress-bg;
        }

        .k-file-success .k-file-name {
            color: $upload-success-text;
        }

        .k-file-success .k-progress {
            background-color: $upload-success-bg;
        }

        .k-file-error .k-file-name {
            color: $upload-error-text;
        }

        .k-file-error .k-progress,
        .k-file-invalid .k-progress {
            background-color: $upload-error-bg;
        }

        .k-file-extension-wrapper,
        .k-multiple-files-extension-wrapper {
            color: $upload-disabled-text;
            border-color: $upload-disabled-border;
        }

        .k-file-invalid .k-file-name-invalid {
            color: $upload-error-text;
        }

        .k-file-invalid-extension-wrapper,
        .k-multiple-files-invalid-extension-wrapper,
        .k-file-error .k-file-extension-wrapper {
            color: $upload-error-text;
            border-color: $upload-error-border;
        }

        .k-file-extension-wrapper::before,
        .k-multiple-files-extension-wrapper::before {
            background-color: $upload-files-bg;
            border-color: transparent transparent $upload-disabled-border $upload-disabled-border;
        }

        .k-file-invalid-extension-wrapper::before,
        .k-multiple-files-invalid-extension-wrapper::before,
        .k-file-error .k-file-extension-wrapper::before {
            background-color: $upload-files-bg;
            border-color: transparent transparent $upload-error-border $upload-error-border;
        }

        .k-multiple-files-extension-wrapper::after {
            border-top-color: $upload-disabled-border;
            border-left-color: $upload-disabled-border;
        }

        .k-multiple-files-invalid-extension-wrapper::after {
            border-top-color: $upload-error-border;
            border-left-color: $upload-error-border;
        }

        .k-file-size,
        .k-file-information,
        .k-file-validation-message {
            color: $upload-disabled-text;
        }
    }

    .k-dropzone {
        .k-i-loading {
            border-color: $header-bg;
        }

        .k-i-loading::before,
        .k-i-loading::after {
            background-color: $header-bg;
        }
    }

    .k-dropzone-hovered {
        background-color: $hovered-bg;
    }
}
