﻿// ".page" is the class used for all page content next to the sidebar and below the header. For example markup, see Organizations/List.cshtml
.page {

    &__header {
        padding: 0 0 0.5rem 0;
    }

    &__title {
        font-size: 1.8rem;
        letter-spacing: -0.1rem;
        line-height: 1.7rem;
        margin: 0.5rem;
        color: $gray-700;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__content {
        padding: 1.75rem 0;

        section {
            margin-bottom: 1rem;
        }
    }

    &__footer {
    }

    &__fnsDiv {
        z-index: 10000;
        position: fixed;
        margin-left: 25%;
        top: 20px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04), 0px 2px 44px rgba(0, 0, 0, 0.12);
    }
}