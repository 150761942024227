@include exports("maskedtextbox/theme") {
    .k-maskedtextbox {
        .k-i-warning {
            color: $error;
        }

        &.k-state-invalid {
            transition: none;

            .k-textbox {
                color: $error;
                border-color: $error;
            }
        }
    }
}