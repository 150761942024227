$menu-bg: none !default;
$menu-text: $link-text !default;
$menu-hover-text: $link-hover-text !default;
$menu-expanded-text: $base-text !default;
$menu-selected-text: $list-item-selected-text !default;
$menu-focused-shadow: $list-item-focused-shadow !default;

$menu-scrolling-bg: $bg-color !default;
$menu-scrolling-border: $widget-border !default;
$menu-scrolling-icon: $link-text !default;
$menu-scrolling-icon-hover: $link-hover-text !default;

@include exports("menu/theme") {

    .k-menu:not(.k-context-menu) {
        border-width: 0;
        background: $menu-bg;
        background-clip: padding-box;

        .k-ie11 &,
        .k-edge12 &,
        .k-edge13 & {
            background-clip: border-box;
        }

        > .k-item {
            color: $menu-text;

            &:hover,
            &.k-state-hover {
                color: $menu-hover-text;
            }

            > .k-state-active {
                color: $menu-expanded-text;
            }

            &.k-state-selected {
                color: $menu-selected-text;
            }

            &:focus,
            &.k-state-focused {
                box-shadow: $menu-focused-shadow;
                outline: 0;
            }
        }
    }

    .k-menu-group,
    .k-menu.k-context-menu {
        @include appearance( popup );

        .k-item {
            &:hover,
            &.k-state-hover {
                @include appearance( hovered-list-item );
            }

            > .k-state-active {
                color: $list-item-selected-text;
                background-color: $list-item-selected-bg;
            }

            &.k-state-selected {
                @include appearance( selected-list-item );
            }

            &:focus,
            &.k-state-focused {
                box-shadow: $menu-focused-shadow;
                outline: 0;
            }
        }
    }


    // Scrolling
    .k-menu-scroll-button {
        border-color: $menu-scrolling-border;
        color: $menu-scrolling-icon;
        background: $menu-scrolling-bg;

        &:hover {
            border-color: $menu-scrolling-border;
            color: $menu-scrolling-icon-hover;
            background: $menu-scrolling-bg;

            &::before {
                opacity: 0;
            }
        }
    }
}
