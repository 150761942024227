@include exports("input/theme") {

    $radio-bg: lighten($input-border, 6.7) !default;
    $radio-border: $radio-bg !default;
    $radio-hovered-border: $input-hovered-border !default;
    $radio-checked-bg: $accent !default;
    $radio-checked-text: $accent-contrast !default;

    $checkbox-bg: lighten($input-border, 6.7) !default;
    $checkbox-border: $checkbox-bg !default;
    $checkbox-hovered-border: $input-hovered-border !default;
    $checkbox-checked-bg: $accent !default;
    $checkbox-checked-border: $accent !default;
    $checkbox-checked-text: $accent-contrast !default;

    $focused-checked-shadow: 0 0 0 1px $panel-bg, 0 0 0 3px $accent !default;

    $fieldset-font-size: $h4-font-size;

    // Input, Textbox, Textarea
    .k-input,
    .k-textbox,
    .k-textarea,
    .k-input.k-textbox {
        @include appearance( "input" );
        transition: $transition;

        // Hovered
        &:hover,
        &.k-state-hover {
            border-color: $input-hovered-border;
        }

        // Focused
        &:focus,
        &.k-state-focused {
            border-color: $input-focused-border;
            box-shadow: none;
        }

        // Invalid
        &:invalid,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            color: $error;
            border-color: $invalid-border;
        }

        // Selection
        &::selection {
            background-color: $accent;
            color: $accent-contrast;
        }

        // Placeholder
        &::placeholder {
            color: inherit;
            opacity: .7;
        }
    }

    // Radio
    .k-radio-label::before {
        border-color: $radio-border;
        background-color: $radio-bg;
    }
    .k-radio {

        // Hover
        &:not(:disabled) + .k-radio-label {}

        // Checked
        &:checked + .k-radio-label {
            &::before {
                background-color: $radio-checked-bg;
                border-color: transparent;
                box-shadow: 0 0 1px 0 $radio-checked-bg inset;
            }

            &::after {
                background-color: $radio-checked-text;
            }
        }

        // Checked && Hovered
        &:not(:disabled):checked + .k-radio-label:hover {}

        // Focused
        &:focus + .k-radio-label::before {
            box-shadow: $focused-checked-shadow;
        }
    }

    // Checkbox
    .k-checkbox-label::before {
        background-color: $checkbox-bg;
        border-color: $checkbox-border;
    }
    .k-checkbox {

        // Hover
        &:not(:disabled) + .k-checkbox-label:hover::before {}

        // Checked
        &:checked + .k-checkbox-label {
            &::before {
                border-color: $checkbox-checked-border;
                background-color: $checkbox-checked-bg;
            }

            &::after {
                color: $checkbox-checked-text;
            }
        }

        // Indeterminate
        &:indeterminate + .k-checkbox-label::after {
            border-color: $checkbox-checked-border;
            background-color: $checkbox-checked-bg;
        }

        // Focused
        &:focus + .k-checkbox-label::before {
            box-shadow: $focused-checked-shadow;
        }

    }

    // .k-checkbox:focus:checked + .k-checkbox-label::before,
    // .k-radio:focus:checked + .k-radio-label::before {
    //     border-color: $accent;
    //     box-shadow: $custom-control-focus-indicator-box-shadow;
    // }

    // Forms
    .k-form,
    .k-form-inline {
        legend {
            font-size: $font-size * 1.5;
            font-weight: normal;
            line-height: $line-height;
            text-transform: none;
        }

        .k-form-field,
        .k-form-field:last-child {
            margin-bottom: $spacer;
        }

        .k-field-info {
            opacity: .7;
        }
    }

    .k-form {
        legend {
            margin-bottom: $padding-y;
        }
        .k-form-field {
            > span:not(.k-widget) {
                padding: 0;
                margin: 0 0 $padding-y;
            }
        }

        .k-field-info {
            margin: 0 $padding-x / 2;
        }
    }

    .k-form-inline {
        legend {
            margin-bottom: 0;
        }

        .k-form-field {
            > span:not(.k-widget),
            > label:not(.k-checkbox-label):not(.k-radio-label) {
                padding: add-two($padding-y, -$input-border-width) 0;
                padding-right: $padding-x;
            }
        }
    }

    .k-required,
    .k-required.k-field-info {
        color: $accent;
    }

    fieldset {
        border-width: 0;
        margin: 0;
        padding: 0;
    }

    fieldset legend {
        color: $header-text;
        font-size: $fieldset-font-size;
    }

    .k-maskedtextbox .k-state-invalid + .k-i-warning {
        color: $error;
    }
}
