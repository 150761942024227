﻿#subscriptionsExist .fieldlist {
    padding-left: 0.5em;
}

#subscriptionsExist .fieldlist li {
    list-style: none;
    padding-bottom: 0.5em;
}

#subscriptionsExist .datepicker_hidden .k-select {
    opacity: 0.1;
}

#subscriptionsExist .k-grid {
    border: 0px solid
}

#subscriptionsExist .k-grid .k-alt {
    background-color: transparent;
}

#subscriptionsExist .k-grid tr.k-alt:not(.k-state-selected):hover {
    /* background: rgba(0,86,230,.05); */
    background-color: transparent;
}

#subscriptionsExist .k-grid .k-grid-header {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 5px;
}

#subscriptionsExist .k-grid table tbody tr td {
    border-bottom: 1px solid #E2E2E2;
}

#subscriptionsExist .k-grid table tbody tr {
    border-bottom: 1px solid #E2E2E2;
}

#subscriptionsExist .k-grid tr th {
    border-bottom: 1px solid #E2E2E2;
}

#subscriptionsExist .k-grid-header .k-header {
    height: 25px;
    padding: 5px;
}

#subscriptionsExist .k-grid tbody tr {
    height: 1rem;
}

#subscriptionsExist .k-grid tbody td {
    padding: 5px;
}

#subscriptionsExist .k-grid-header-wrap.k-auto-scrollable {
    margin-right: 0px;
    padding-right: 10px;
}

#subscriptionsExist .tabstrip.k-widget.k-header.k-tabstrip.k-floatwrap.k-tabstrip-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

#subscriptionsExist .tabstrip.k-widget.k-header.k-tabstrip.k-floatwrap.k-tabstrip-top:not(:last-child) {
    margin-right: 5px;
}

#subscriptionsExist .fieldlist.text-secondary.text-sm {
    margin-bottom: 3px;
}

#subscriptionsExist .users-ddl {
    padding: 5px;
    margin: 5px;
}

#subscriptionsExist .k-detail-row.k-alt td {
    background-color: transparent;
}

#subscriptionsExist .border-top-noedge {
    border-top: 1px solid #E2E2E2 !important;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 5px;
    padding: 3px;
    display: flex;
}

#subscriptionsExist .panelframe {
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    margin-top: 1rem;
}

#subscriptionsExist.innerframe {
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background: white;
}

.page__content {
    padding: 0rem;
    padding-top: 1rem;
}

#subscriptionsExist .subscription_search {
    margin-right: 1rem;
}

#subscriptionsExist .k-grid-content-locked tr:last-child td, .k-grid-content tr:last-child td {
    border-bottom-width: 1px;
}

#subscriptionsExist .sublicenses-grid {
    box-shadow: none;
    width: 100%;
}

#subscriptionsExist .k-grid-edit-row .k-edit-cell {
    padding: 0.8rem;
}

#subscriptionsExist .k-edit-cell > .k-textbox, .k-edit-cell > .k-widget, .k-grid-edit-row td > .k-textbox, .k-grid-edit-row td > .k-switch {
    width: 75px
}

#addUserToLicense {
    color: white;
    background-color: #0048c0;
    border-color: #0043b3;
}

#subscriptionGrid colgroup col.k-group-col {
    width: 10px !important;
}

span.group-title {
    font-size: 1.125rem;
}

.sublicenses-grid > .k-grid-header {
    display: none;
}