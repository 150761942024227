@include exports("mediaplayer/layout") {

    // Base
    .k-mediaplayer {
        position: relative;

        > iframe {
            width: 100%;
            height: 100%;
            border: 0;
            vertical-align: top;
        }
    }


    // Title bar
    .k-mediaplayer-titlebar {
        padding: $header-padding-y $header-padding-x;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
    }


    // Toolbar
    .k-mediaplayer-toolbar-wrap {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .k-mediaplayer-toolbar {
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-width: 0;
        // sass-lint:disable no-important
        width: 100% !important;
        // sass-lint:enable no-important


        > * {
            align-items: center;
            align-content: center;
        }

        .k-toolbar-spacer {
            margin-top: 0;
            margin-bottom: 0;
            border-width: 0;
            flex: 1;
        }

        .k-align-right {
            & > * + * { margin-left: $padding-x; }
        }

    }
    .k-mediaplayer-time-wrap {
        flex: 1;
    }
    .k-mediaplayer-volume-wrap {
        align-items: center;
    }


    // Seekbar
    .k-slider.k-mediaplayer-seekbar {
        width: 100%;
        position: absolute;
        z-index: 3;
        top: -17px;
        left: 0;
    }
    .k-mediaplayer-seekbar .k-slider-track {
        // sass-lint:disable no-important
        width: 100% !important;
        // sass-lint:enable no-important
        border-radius: 0;
    }

    .k-mediaplayer-fullscreen {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        // sass-lint:disable no-important
        width: 100% !important;
        height: 100% !important;
        // sass-lint:enable no-important
    }

}
