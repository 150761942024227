﻿.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    
    &__icon {
        font-size: 12rem;    
        color: rgba($black, .075);
    }

    &__instructions {
    
    }

    &__button {
    
    }

}
