@include exports("chat/theme") {

    // Chat
    .k-chat {
        border-color: $chat-border;
        color: $chat-text;
        background-color: $chat-bg;


        // Message meta
        .k-timestamp {
            text-transform: uppercase;
            opacity: .7;
        }
        .k-author {
            font-weight: bold;
        }


        // Bubbles
        .k-bubble {
            @include box-shadow( 0 1px 2px rgba( 0, 0, 0, .08) );
            border-color: $chat-bubble-border;
            color: $chat-bubble-text;
            background-color: $chat-bubble-bg;
            transition: box-shadow .2s ease-in-out;
            order: -1;
        }
        .k-bubble:hover {
            @include box-shadow( 0 1px 2px rgba( 0, 0, 0, .16) );
        }
        .k-state-selected .k-bubble {
            @include box-shadow( 0 3px 10px rgba( 0, 0, 0, .16) );
        }

        .k-alt .k-bubble {
            @include box-shadow( 0 1px 2px rgba( $chat-alt-bubble-bg, .2 ) );
            border-color: $chat-alt-bubble-border;
            color: $chat-alt-bubble-text;
            background-color: $chat-alt-bubble-bg;
        }
        .k-alt .k-bubble:hover {
            @include box-shadow( 0 1px 2px rgba( $chat-alt-bubble-bg, .2 ) );
        }
        .k-alt .k-state-selected .k-bubble {
            @include box-shadow( 0 3px 10px rgba( $chat-alt-bubble-bg, .4 ) );
        }


        // Quick replies
        .k-quick-reply {
            border-color: $accent;
            color: $accent;
            background-color: transparent;
        }
        .k-quick-reply:hover {
            border-color: $accent;
            color: $accent-contrast;
            background-color: $accent;
        }


        // Message box
        .k-message-box {
            border-color: inherit;
            color: $input-text;
            background-color: $input-bg;
        }
        .k-message-box.k-state-focused {
            box-shadow: 0 0 40px rgba( $input-text, .1 );
        }
        .k-message-box .k-button:hover {
            color: $accent;
        }

    }

}
