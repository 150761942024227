﻿.card {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 2px 14px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 8px !important;
    transition: box-shadow 300ms ease-in-out;
    height: 100%;

    .card-title {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.6875rem;
    }

    &.card-link {
        box-shadow: none;
        border: 1px solid rgba(0,0,0,.125);

        &:hover {
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 2px 14px rgba(0, 0, 0, 0.12);
            cursor: pointer;
        }

        @media (max-width: 599px) {
            .d-flex {
                display: block !important;
            }
        }
    }
}

.card-subtitle
{
    font: .75rem !important;
    padding-top: 10px;
}

.shaped-icon {
    width: 70px;
    background-color: rgba(0,0,0,0.05);
    border-radius: 75% 47% 85% 42%/95% 80% 54% 30%;
    padding: 20px;
    display: -ms-flexbox;
    display: flex;

    .fa-folders {
        &::before {
            color: rgba(0,0,0,0.5);
        }

        &::after {
            color: rgba(0,0,0,0.2);
        }
    }
}