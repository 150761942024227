$tooltip-padding-y: $padding-y !default;
$tooltip-padding-x: $padding-x !default;
$tooltip-closable-padding-x: $window-titlebar-padding-x !default;
$tooltip-closable-padding-y: $window-titlebar-padding-y !default;
$tooltip-closable-content-padding: calc(#{$icon-size} + #{$icon-spacing}) !default;
$tooltip-font-size: $font-size !default;
$tooltip-line-height: $line-height !default;
$tooltip-callout-size: 12px !default;

@include exports("tooltip/layout") {
    $callout-half-size: $tooltip-callout-size / 2;

    .k-tooltip {
        @include border-radius();
        line-height: $tooltip-line-height;
        padding: $tooltip-padding-y $tooltip-padding-x;
        border-width: 0;
        box-sizing: border-box;
        background-repeat: repeat-x;
        position: absolute;
        z-index: 12000;

        &:not(.k-tooltip-closable) {
            font-size: $tooltip-font-size;
        }
    }

    .k-tooltip-content {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .k-tooltip-closable {
        padding: $tooltip-closable-padding-y $tooltip-closable-padding-x;
        line-height: normal;

        .k-tooltip-content {
            padding-right: $tooltip-closable-content-padding;

            .k-rtl &,
            [dir-rtl] & {
                padding-left: $tooltip-closable-content-padding;
                padding-right: 0;
            }
        }
    }

    .k-tooltip-button {
        position: absolute;
        top: $tooltip-closable-padding-y;
        right: $tooltip-closable-padding-x;

        .k-icon {
            color: inherit;
        }

        .k-rtl &,
        [dir-rtl] & {
            left: $tooltip-closable-padding-x;
            right: auto;
        }
    }

    // Callout
    .k-callout {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $callout-half-size;
        border-color: transparent;
        pointer-events: none;
    }

    .k-callout-n {
        left: 50%;
        margin-left: -$callout-half-size;
        top: -$tooltip-callout-size;
        pointer-events: none;
    }

    .k-callout-w {
        top: 50%;
        margin-top: -$callout-half-size;
        left: -$tooltip-callout-size;
        pointer-events: none;
    }

    .k-callout-s {
        left: 50%;
        margin-left: -$callout-half-size;
        bottom: -$tooltip-callout-size;
        pointer-events: none;
    }

    .k-callout-e {
        top: 50%;
        margin-top: -$callout-half-size;
        right: -$tooltip-callout-size;
        pointer-events: none;
    }
}
