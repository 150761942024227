@include exports("editor/layout") {

    // Base
    .k-editor {
        border-collapse: separate;
        border-spacing: 0;
        vertical-align: top;
        position: relative;
        table-layout: fixed;

        .k-content {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            border: 0;
            background: none;
            display: block;
        }
    }

    table.k-editor {
        width: 100%;
    }

    .k-editor-inline {
        @include border-radius();
        padding: $padding-y-sm $padding-x-sm;
        border: 1px solid transparent;
        word-wrap: break-word;
        overflow: auto;
        background: none;
        transition: border-color .3s;
    }
    .k-window.k-editor-widget {
        padding: 0;
    }
    // sass-lint:disable class-name-format
    .editorToolbarWindow {
        padding: 0;
        display: flex;
        align-items: stretch;
    }
    .k-editortoolbar-dragHandle {
        margin: $toolbar-padding-y;
        padding: 0;
        cursor: move;
    }
    // sass-lint:enable class-name-format


    // Toolbar
    .k-editor-toolbar-wrap {
        border-color: inherit;
    }
    .k-editor-toolbar {
        margin: 0;
        padding: $toolbar-padding-y $toolbar-padding-y;
        border-color: inherit;
        list-style-type: none;
        line-height: $form-line-height;
        cursor: default;
        word-wrap: break-word; /* allow tools to wrap properly in IE */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;

        &.k-toolbar-resizable {
            flex-wrap: nowrap;
            overflow: hidden;
            flex: 1 1 0%;
        }

        li {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
        }


        // Group
        .k-tool-group {
            padding: 0;
            border-width: 0;
            border-style: solid;
            border-color: inherit;

            .k-widget {}
            .k-widget + .k-widget {
                margin-left: $toolbar-padding-x;
            }
        }
        .k-tool-group + .k-tool-group {
            margin-left: $toolbar-padding-x;
        }
        .k-tool-group .k-state-disabled,
        .k-tool-group.k-state-disabled {
            display: none;
        }


        // Tools
        .k-tool {
            padding: $button-padding-y;
            width: button-size();
            height: button-size();
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            text-decoration: none;
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;
        }
        .k-tool-text {
            display: none;
        }
        .k-tool + .k-tool {
            margin-left: -1px;
        }

        // Overflow tools
        .k-overflow-tools {
            position: absolute;
            top: 0;
            right: 0;
            visibility: hidden;
        }

    }


    // Editable area
    .k-editable-area {
        padding: $input-padding-y;
        width: 100%;
        height: 100%;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: inherit;
        outline: 0;

        .k-resizable & {
            padding: $input-padding-y $input-padding-y 16px;
        }
    }

    .k-edit-form-content {
        flex: 1 1 auto;
        overflow: auto;
        margin: (-$window-inner-padding-y) (-$window-inner-padding-x);
        padding: $window-inner-padding-y $window-inner-padding-x;
    }


    $ct-cell-size: 20px;

    // Insert table
    .k-ct-popup {
        box-sizing: border-box;
        width: $ct-cell-size * 10 - ($ct-cell-size / 2);
        padding: $ct-cell-size / 4;

        .k-editor-toolbar {
            text-align: center;

            .k-tool {
                @include border-radius();
                width: 100%;
                height: auto;
                display: flex;
            }
            .k-tool-text {
                display: inline;
            }
        }

        .k-ct-cell {
            margin: 1px;
            width: $ct-cell-size;
            height: $ct-cell-size;
            box-sizing: border-box;
            border: 1px solid;
            border-color: inherit;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            opacity: .7;
        }
    }

    .k-editor .k-resize-handle {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 13px 13px;
        border-color: transparent;
        border-bottom-color: inherit;
        cursor: se-resize;

        .k-i-arrow-45-down-right {
            display: none;
        }
    }

    .k-editor .k-overlay {
        position: absolute;
        background-color: #fff;
        opacity: 0;
    }

    .k-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .5;
    }

    .k-editor-widget .k-colorpicker {}

    .k-rtl .k-editor .k-editor-widget .k-dropdown-wrap {
        padding-left: 0;

        .k-select {
            border-width: 0;
        }
    }
}




@include exports( "editor/dialog/layout" ) {


    .k-editor-dialog {
        box-sizing: border-box;

        .k-edit-form-container {
            width: auto;
        }

        .k-edit-label {
            width: 30%;
            padding: add-two($padding-y, $input-border-width) 0;
        }

        .k-edit-field {
            width: 66%;
        }

        .k-edit-buttons {}
    }

}




@include exports( "editor/filebrowser/layout" ) {


    .k-filebrowser-dialog {
        display: flex;

        .k-edit-form-container {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

        }

        .k-edit-label {
            width: 18%;
        }

        .k-edit-field {
            width: 75%;
        }
    }

    .k-filebrowser {
        max-width: 100%;

        // Breadcrumbs and search
        .k-floatwrap {
            display: flex;

            &::after { display: none; }
        }
        .k-breadcrumbs {
            flex: 1;
        }
        .k-search-wrap {
            margin: 0 0 0 $spacer-x;
            width: 150px;
            display: flex;
            align-items: center;

            .k-input {
                flex: 1;
                width: 100px; // fix width for Edge
            }
            .k-icon {
                margin: 0;
                position: static;
            }
        }

        // Filebrowser toolbar
        .k-filebrowser-toolbar {
            margin: $spacer-y 0 0 0;
            padding: $toolbar-padding-y $toolbar-padding-x;
            display: flex;
            justify-content: space-between;
        }
        .k-toolbar-wrap {
            display: flex;

            > * + * {
                margin-left: $toolbar-padding-x;
            }

            .k-state-disabled {
                display: none;
            }
        }

        .k-upload {
            padding: 0;
            border-width: 0;
            background: none;

            .k-upload-button {
                margin: 0;
            }

            .k-upload-status {
                display: none;
            }
        }

        .k-upload-files {
            display: none;
        }

        // Tiles
        .k-tiles {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: 390px;
            max-height: 50vh;
            margin: 0 0 $spacer-y 0;
            padding: $padding-y $padding-x;
            border-top-width: 0;
            overflow: auto;
        }

        .k-tile {
            width: 33%;
            height: 90px;
            padding: $padding-y $padding-x;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            @include border-radius();

            .k-i-file,
            .k-i-folder {
                font-size: 4em;
            }

            input {
                width: 100px;
            }

            strong {
                display: block;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .k-tile-empty {
            display: block;
            margin: auto;

            &.k-state-selected {
                color: inherit;
                border-width: 0;
                background-image: none;
                background-color: transparent;
            }

            strong {
                opacity: .5;
                font-size: 3em;
                font-weight: 400;
            }
        }

        .k-thumb {
            float: left;
            margin-right: $padding-x-sm;
        }

        .k-breadcrumbs-wrap {
            position: absolute;
            left: $input-padding-x;
            top: $input-padding-y;

            .k-icon {
                position: static;
                margin-top: 0;
            }
        }
    }

}




@include exports( "editor/table-wizard/layout" ) {

    .k-editor-table-wizard-dialog {
        display: flex;

        // Edit form
        .k-edit-form-container {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        // Tabstrip wrapper
        .k-tabstrip-wrapper {
            display: flex;
            flex: 1 1 auto;
            overflow: auto;

            .k-tabstrip.k-root-tabs {
                flex: 1 1 auto;
                margin-bottom: 0;
            }
        }

        .k-edit-field > .k-checkbox {
            position: relative; // fix scrolling
        }

        // Generic widgets
        .k-numerictextbox {
            width: 10em;

            & + .k-dropdown {
                width: 4em;
            }

            & + .k-colorpicker,
            & + .k-dropdown {
                margin-left: $padding-x-sm;
            }
        }

        .k-colorpicker {
            vertical-align: middle;
        }

        .k-edit-field > .k-checkbox {
            position: relative; // fix scrolling
        }


        // Align
        .k-dropdown.k-align {
            width: auto;
        }
    }


    .k-popup.k-align {

        .k-list {
            display: flex;
            flex-flow: row wrap;
        }
        .k-list .k-item {
            padding: $button-padding-y;
            height: button-size();
            box-sizing: border-box;
            justify-content: center;
            flex: 1 0 33%;

            .k-icon {
                margin: 0;
            }
        }
        .k-list .k-item:last-child {
            flex: 1 0 100%;
        }

    }

    .k-editor-inline {

        .k-table {
            width: 100%;
            border-spacing: 0;
            margin: 0 0 1em;

            &,
            & td {
                outline: 0;
                border: 1px dotted #ccc;
            }

            td {
                min-width: 1px;
                padding: $padding-y-sm $padding-x-sm;
            }
        }

        .k-table-resize-handle-wrapper {
            position: absolute;
            width: 5px;
            height: 5px;
            background-color: #fff;
            border: 1px solid #000;
            z-index: 100;

            .k-table-resize-handle {
                width: 100%;
                height: 100%;

                &.k-resize-east {
                    cursor: e-resize;
                }

                &.k-resize-north {
                    cursor: n-resize;
                }

                &.k-resize-northeast {
                    cursor: ne-resize;
                }

                &.k-resize-northwest {
                    cursor: nw-resize;
                }

                &.k-resize-south {
                    cursor: s-resize;
                }

                &.k-resize-southeast {
                    cursor: se-resize;
                }

                &.k-resize-southwest {
                    cursor: sw-resize;
                }

                &.k-resize-west {
                    cursor: w-resize;
                }
            }
        }

        .k-column-resize-handle-wrapper {
            position: absolute;
            height: 10px;
            width: 10px;
            cursor: col-resize;
            z-index: 2;

            .k-column-resize-handle {
                width: 100%;
                height: 100%;

                .k-column-resize-marker {
                    width: 2px;
                    height: 100%;
                    margin: 0 auto;
                    background-color: #00b0ff;
                    display: none;
                    opacity: .8;
                }
            }
        }

        .k-row-resize-handle-wrapper {
            position: absolute;
            z-index: 2;
            cursor: row-resize;
            width: 10px;
            height: 10px;

            .k-row-resize-handle {
                display: table;
                width: 100%;
                height: 100%;
            }

            .k-row-resize-marker-wrapper {
                display: table-cell;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                vertical-align: middle;
            }

            .k-row-resize-marker {
                display: none;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 2px;
                background-color: #00b0ff;
                opacity: .8;
            }
        }
    }

}
