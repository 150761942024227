$checkbox-size: $icon-size !default;

@include exports( 'input/layout' ) {

    $checkbox-radius: $border-radius !default;
    $radio-radius: 50% !default;
    $textbox-shadow: 0 2px 2px 1px rgba(0, 0, 0, .06) !default;
    $fieldset-margin: 30px;
    $checkbox-border-width: 1px !default;
    $checkbox-line-height: $checkbox-size + $checkbox-border-width !default;
    $checkbox-indeterminate-style: square !default;
    $checkbox-dash-height: $checkbox-size / 10 !default;
    $checkbox-dash-width: $checkbox-size - ( $checkbox-border-width * 4 ) !default;
    $checkbox-dash-offset-x: $checkbox-border-width * 2 !default;
    $checkbox-dash-offset-y: ( $checkbox-size - $checkbox-dash-height ) / 2 !default;

    $textbox-font-size: $font-size !default;
    $textbox-line-height: $form-line-height-em !default;
    // TODO: legacy, consider switching to 100%
    $textbox-default-width: $input-default-width !default;
    $textarea-default-width: 18em !default;

    .k-textbox,
    .k-input.k-textbox,
    .k-textarea {
        @include border-radius();
        padding: $input-padding-y $input-padding-x;
        width: $textbox-default-width;
        box-sizing: border-box;
        border-width: $input-border-width;
        border-style: solid;
        outline: 0;
        font: inherit;
        font-size: $textbox-font-size;
        line-height: $textbox-line-height;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        -webkit-appearance: none;
    }

    .k-textbox,
    .k-input.k-textbox {
        height: calc(#{$textbox-line-height} + (#{$input-padding-y} * 2) + (#{$input-border-width} * 2));
    }

    .k-textarea {
        width: $textarea-default-width;
        min-height: calc((#{$line-height-em} * 4) + (#{$input-padding-y} * 2));
    }

    .k-maskedtextbox {
        display: inline-flex;
        border-width: 0;

        .k-textbox {
            flex: 1 0 0;
            min-width: 0;
        }
    }

    .k-input,
    .k-textbox > input {
        padding: 0;
        width: 100%;
        box-sizing: border-box;
        border: 0;
        outline: 0;
        color: inherit;
        background: none;
        font: inherit;
        font-size: $textbox-font-size;
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-appearance: none;
    }

    .k-input,
    .k-textbox > input,
    .k-textbox {
        &::-ms-clear { display: none; }
    }

    .k-textbox:focus {
        box-shadow: $textbox-shadow;
    }

    .k-textbox {
        &:disabled,
        &[disabled],
        &.k-state-disabled {
            @include disabled;
        }
    }


    // input container / floating label
    $floating-label-transition: .2s ease-out !default;
    $floating-label-scale: .75 !default;
    $floating-label-height: $textbox-line-height * $floating-label-scale;

    .k-textbox-container {
        position: relative;
        padding-top: $floating-label-height;
        display: inline-flex;
        width: $textbox-default-width;
        flex-direction: column;
        justify-content: stretch;

        > .k-label {
            pointer-events: none;
            position: absolute;
            line-height: $textbox-line-height;
            cursor: text;
            top: add-three($floating-label-height, $input-border-width, $input-padding-y);
            left: add-two($input-padding-x, $input-border-width);
            transition: transform $floating-label-transition, color $floating-label-transition;
        }

        > .k-textbox,
        > .k-textarea,
        > .k-widget {
            flex: 1 1 auto;
            width: auto;
        }

        &.k-state-empty {
            > .k-label {
                transform: translate( 0, 0 ) scale( 1 );
            }
        }

        > .k-label,
        &.k-state-focused > .k-label {
            // persist alignment after scaling
            $half-scale-percent: ((1 - $floating-label-scale) * 100% / 2);

            // sass-lint:disable indentation
            // transform position to top-left corner of the textbox-container
            // do not use single translate + calc() because of IE10/11
            $fls: -$floating-label-scale;
            transform: translate(-$input-padding-x,    $fls * $input-padding-y)
                       translate(-$input-border-width, $fls * $textbox-line-height)
                       translate(-$half-scale-percent, $fls * $half-scale-percent)
                       scale($floating-label-scale);
            // sass-lint:enable indentation
        }

        .k-rtl &,
        &[dir='rtl'] {

            > .k-label {
                left: auto;
                right: add-two($input-padding-x, $input-border-width);
            }

            &.k-state-empty {
                > .k-label {
                    transform: translate( 0, 0 ) scale( 1 );
                }
            }

            > .k-label,
            &.k-state-focused > .k-label {
                $half-scale-percent: ((1 - $floating-label-scale) * 100% / 2);

                // sass-lint:disable indentation
                $fls: -$floating-label-scale;
                transform: translate($input-padding-x,    $fls * $input-padding-y)
                            translate($input-border-width, $fls * $textbox-line-height)
                            translate($half-scale-percent, $fls * $half-scale-percent)
                            scale($floating-label-scale);
                // sass-lint:enable indentation
            }
        }
    }


    // checkbox / radio button
    .k-checkbox,
    .k-radio {
        margin: 0;
        padding: 0;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        position: absolute;
        opacity: 0;
        -webkit-appearance: none;
        pointer-events: none;
    }
    .k-radio:disabled + .k-radio-label,
    .k-checkbox:disabled + .k-checkbox-label {
        @include disabled;
    }


    .k-checkbox-label,
    .k-radio-label {
        margin: 0;
        padding-left: add-two($checkbox-size, $icon-spacing);
        min-height: $icon-size;
        line-height: $checkbox-line-height;
        vertical-align: text-top;
        display: inline-flex;
        align-items: flex-start;
        position: relative;
        cursor: pointer;

        .k-ripple {
            top: $checkbox-size / 2;
            left: $checkbox-size / 2;
            right: auto;
            bottom: auto;
            width: $checkbox-size * 5 / 2;
            height: $checkbox-size * 5 / 2;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }

        .k-ripple-blob {
            // sass-lint:disable-block no-important
            // use !important until ripple can apply these styles from the script
            top: 50% !important;
            left: 50% !important;
            width: 200% !important;
            height: 200% !important;
        }

        // With no text
        &.k-no-text {
            padding: 0;
            width: $icon-size;
            height: $icon-size;
            display: inline-block;
        }

        .k-rtl &,
        [dir='rtl'] & {
            padding-left: 0;
            padding-right: add-two($checkbox-size, $icon-spacing);
        }
    }

    .k-checkbox-label::before,
    .k-checkbox-label::after,
    .k-radio-label::before,
    .k-radio-label::after {
        font-size: $icon-size;
        font-family: "WebComponentsIcons", monospace;
        box-sizing: border-box;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;

        .k-rtl &,
        [dir='rtl'] &  {
            left: auto;
            right: 0;
        }
    }

    // Container box
    .k-checkbox-label::before,
    .k-radio-label::before {
        content: "";
        width: $checkbox-size;
        height: $checkbox-size;
        border-width: $checkbox-border-width;
        border-style: solid;
    }
    .k-checkbox-label::before {
        @include border-radius( $checkbox-radius );
    }
    .k-radio-label::before {
        border-radius: $radio-radius;
    }

    // Checkbox
    .k-checkbox-label::after {
        content: "\e118";
        width: $checkbox-size;
        height: $checkbox-size;
        transform: scale(0);
    }
    .k-checkbox:checked + .k-checkbox-label::after {
        @include border-radius( $checkbox-radius );
        transform: scale(1);
    }
    .k-checkbox:indeterminate + .k-checkbox-label::after {
        content: "";
        transform: scale(1);

        @if $checkbox-indeterminate-style == 'dash' {
            width: $checkbox-dash-width;
            height: $checkbox-dash-height;
            top: $checkbox-dash-offset-y;
            left: $checkbox-dash-offset-x;
        } @else {
            width: $checkbox-size / 2;
            height: $checkbox-size / 2;
            top: $checkbox-size / 4;
            left: $checkbox-size / 4;
        }
    }

    // Radio
    .k-radio-label::after {
        content: "";
        width: $checkbox-size / 2;
        height: $checkbox-size / 2;
        border-radius: 50%;
        position: absolute;
        top: $checkbox-size / 4;
        left: $checkbox-size / 4;
        transform: scale(0);
    }
    .k-radio:checked + .k-radio-label::after {
        transform: scale(1);
    }

    // Check / Radio list
    .k-radio-list {
        .k-radio-label {
            line-height: $checkbox-size * 1.25;
        }
    }

    .k-fieldset {
        margin: $fieldset-margin;
        border-width: 1px 0 0;
        border-style: solid;
        padding: 25px 0 0;

        > legend {
            margin-left: 0;
            padding: 0 $padding-x 0 0;
            text-transform: uppercase;
        }
    }

    // Forms
    $form-spacer: $padding-x * 2 !default;

    .k-form,
    .k-form-inline {
        font-size: $font-size;
        line-height: $line-height;
        padding: $form-spacer;

        fieldset {
            border-width: 1px 0 0;
            border-style: solid;
            margin: ($form-spacer * 2) 0;
            padding: 0;

            &:first-child:first-of-type {
                margin-top: 0;
            }

            &:last-child:last-of-type {
                margin-bottom: 0;
            }
        }

        legend {
            font-size: $font-size-sm;
            text-align: left;
            font-weight: 600;
            line-height: 1;
            margin-bottom: $padding-y-lg * 2;
            text-transform: uppercase;
            padding: 0 $padding-x 0 0;
            width: auto;
        }

        .k-form-field {
            display: block;
            text-align: left;
            margin-bottom: $padding-y-lg * 2;

            > span {
                line-height: $line-height;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .k-alert-error {
            font-size: $font-size-sm;
            margin-top: $padding-y * 2;
        }

        .k-field-info {
            display: inline-block;
            font-size: $font-size-xs;
            line-height: 1;
            margin: 0 $padding-x;
        }

        .k-checkbox-label,
        .k-radio-label {
            margin-right: $padding-x * 2;
            align-self: center;
        }
    }

    .k-form {
        input,
        label:not(.k-checkbox-label):not(.k-radio-label),
        .k-widget:not(.k-calendar) {
            display: block;
        }

        .k-form-field {
            > span:not(.k-widget) {
                display: block;
                padding: $padding-y 0;
            }

            > input {
                width: 100%;
            }
        }
    }

    .k-form-inline {
        $inline-form-element-width: 25% !default;

        .k-form-field {
            display: flex;
            align-items: flex-start;


            > span:not(.k-widget),
            > label:not(.k-checkbox-label):not(.k-radio-label) {
                width: $inline-form-element-width;
                text-align: right;
                line-height: $line-height;
                padding: add-two($padding-y, $input-border-width) 0;
                padding-right: $padding-x-lg;
                align-self: center;
            }

            > input {
                flex: 1 1 auto;
            }

            .k-field-info {
                display: block;
                margin: 0;
            }
        }
    }

    .k-treeview,
    .k-grid {
        .k-checkbox-label {
            cursor: default;
            outline: 0;
        }
    }
}
