@include exports("edit-form/layout") {


    // Edit form
    .k-popup-edit-form {}
    .k-edit-form-container {
        width: 400px;
        min-width: 400px;
        border-color: inherit;
        position: relative;
    }


    // Tabstrip
    .k-edit-form-container .k-tabstrip-wrapper {
        margin: (-$window-inner-padding-y) (-$window-inner-padding-x);
    }
    .k-edit-form-container .k-tabstrip.k-root-tabs {
        margin-bottom: $window-inner-padding-y;
    }
    .k-edit-form-container .k-tabstrip .k-tabstrip-items {
        padding: $window-inner-padding-y $window-inner-padding-x 0;
    }
    .k-edit-form-container .k-tabstrip .k-content {
        padding: $window-inner-padding-y $window-inner-padding-x;
        border-width: 0;
    }


    // Edit buttons
    .k-edit-buttons {
        padding: $toolbar-padding-y $toolbar-padding-x;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: inherit;
        text-align: right;
        clear: both;
    }
    .k-edit-buttons .k-button {}
    .k-edit-buttons .k-button + .k-button {
        margin-left: ( $spacer-x / 2 );
    }
    .k-edit-form-container .k-edit-buttons {
        margin: $window-inner-padding-y (-$window-inner-padding-x) (-$window-inner-padding-y);
        padding: $window-inner-padding-y $window-inner-padding-x;
    }


    // Action buttons
    .k-action-buttons {}
    .k-popup-edit-form > .k-action-buttons,
    .k-edit-form-container .k-action-buttons {
        margin: $spacer (-$window-inner-padding-x) (-$window-inner-padding-y);
    }


    .k-edit-label {
        margin: 0 0 $spacer-y 0;
        padding: add-two($input-padding-y, $input-border-width) 0;
        width: 30%;
        line-height: $form-line-height;
        text-align: right;
        float: left;
        clear: both;
    }
    .k-edit-field {
        margin: 0 0 $spacer-y 0;
        width: 65%;
        float: right;
        clear: right;
    }

    .k-edit-field {

        > .k-widget,
        > .k-textbox,
        > .k-input.k-textbox {
            width: 100%;
            box-sizing: border-box;
        }
        > .k-colorpicker {
            width: auto;
        }

        input[type="radio"]:not(.k-radio),
        input[type="checkbox"]:not(.k-checkbox) {
            margin-right: .4ex;
        }

        .k-radio-label,
        .k-checkbox-label {
            margin-right: ($spacer);
        }

        .k-checkbox-label {
            margin-top: add-two($input-padding-y, $input-border-width);
        }

        .k-reset > li + li {
            margin-top: ($spacer / 2);
        }
        .k-reset .k-widget {
            margin: 0 .4ex 0 1ex;
        }
    }

}

@include exports("edit-form/rtl") {

    .k-rtl {

        .k-edit-buttons {
            text-align: left;

            .k-button + .k-button {
                margin-left: 0;
                margin-right: .5em;
            }
        }
    }
}
