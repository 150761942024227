@include exports("colorpicker/layout") {

    .k-colorpicker {
        width: auto;
        border-width: 0;
        display: inline-block;
        position: relative;
        overflow: visible;

        .k-selected-color {
            padding: $button-padding-y;
            width: button-inner-size();
            height: button-inner-size();
            box-sizing: border-box;
            line-height: 0;
            position: relative;
            overflow: hidden;

            .k-i-line {
                border-top: 1px solid $error;
                width: 200%;
                height: 200%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-33%, -33%) rotateZ(45deg);
                transform-origin: 0 0;
            }
            .k-i-line::before {
                display: none;
            }
        }

        .k-tool-icon {
            padding: $button-padding-y;
            width: button-inner-size();
            height: button-inner-size();
            border-width: 0 1px 0 0;
            border-style: solid;
            border-color: inherit;
            box-sizing: border-box;
            font-size: inherit;
            text-align: center;

            &::before {
                font-size: $icon-size;
                line-height: 1;
            }

            .k-selected-color {
                display: inline-block;
                padding: 0;
                margin-bottom: 12px;
                width: 14px;
                height: 3px;
                line-height: 0;
            }
        }

    }

}

@include exports( "flatcolorpicker/layout" ) {

    .k-flatcolorpicker {
        @include border-radius();
        padding: 0 0 $spacer;
        width: 250px;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .k-draghandle {
            @include border-radius( 50% );
            width: $spacer;
            height: $spacer;
            border: 1px solid rgba(255, 255, 255, .8);
            box-shadow: 0 1px 4px rgba(0, 0, 0, .21);
            box-sizing: border-box;
        }

        // Selected color
        .k-selected-color {
            border-width: 0 0 1px;
            border-style: solid;
            border-color: inherit;
            background-position: 50% 50%;
        }
        .k-color-input {
            display: flex;
            flex-direction: row;
            position: relative;
        }
        .k-color-value {
            margin: 0 0 0 button-inner-size(2);
            padding: $input-padding-y $input-padding-x;
            width: 100%;
            border: 0;
            box-sizing: border-box;
            font-size: inherit;
            line-height: $form-line-height;
            font-family: $font-family-monospace;
            flex: 1;
        }
        .k-clear-color-container {
            display: flex;
            flex-direction: row;
        }
        .k-clear-color {
            flex: 1;
        }
        .k-color-value + .k-clear-color {
            flex: none;
            position: absolute;
            top: 0;
            right: 0;
        }

        .k-hsv-rectangle {
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -ms-touch-action: pinch-zoom double-tap-zoom;

            .k-draghandle {
                margin: -7px 0 0 -7px;
                cursor: pointer;
                position: absolute;
                z-index: 10;
                left: 50%;
                top: 50%;
            }
        }

        .k-hsv-gradient {
            margin-bottom: 5px;
            height: 180px;
            background: linear-gradient( to bottom, $gradient-transparent-to-black ), linear-gradient( to right, $gradient-white-to-transparent );
        }

        // Sliders
        > .k-slider {
            margin: $spacer $spacer 0;
        }
        .k-hue-slider,
        .k-transparency-slider {
            display: block;

            .k-draghandle {
                border-width: 3px;
                box-shadow: 0 1px 4px rgba(0, 0, 0, .9);
                // sass-lint:enable no-color-literals no-color-keywords
                background-color: transparent;
            }

            .k-draghandle:hover,
            .k-draghandle:focus {
                // sass-lint:disable no-color-literals no-color-keywords
                border-color: white;
                box-shadow: 0 1px 4px black;
                // sass-lint:enable no-color-literals no-color-keywords
                background-color: transparent;
            }
        }
        .k-hue-slider {
            width: $spacer;
            height: $spacer;

            .k-slider-track {
                background: linear-gradient( to right, $gradient-rainbow );
            }

            .k-slider-selection {
                background: none;
                opacity: 0;
            }
        }

        .k-slider-horizontal {
            width: 90%;
            height: 20px;
        }


        // Buttons
        .k-controls {
            margin: $spacer $spacer 0;
            text-align: center;
        }
    }
}


@include exports( "colorpalette/layout" ) {
    .k-colorpalette {
        border-width: 0;
        line-height: 0;
        display: inline-block;
        position: relative;

        .k-palette {
            width: 100%;
            height: 100%;
            border-collapse: collapse;
            position: relative;
        }
        .k-item {
            width: 14px;
            height: 14px;
            overflow: hidden;
            -ms-high-contrast-adjust: none;
        }
        // TODO: consider extrangting variables
        // sass-lint:disable no-color-literals no-color-keywords
        .k-item:hover {
            box-shadow: 0 1px 3px 1px rgba( black, .5 ), inset 0 0 0 1px rgba( white, .3 );
            position: relative;
            z-index: 101;
        }
        .k-item.k-state-selected,
        .k-item.k-state-selected:hover {
            box-shadow: 0 1px 3px 1px rgba( black, .7 ), inset 0 0 0 1px rgba( white, .45 );
            position: relative;
            z-index: 100;
        }
        // sass-lint:enable no-color-literals no-color-keywords
    }

}
