@include exports("pager/theme") {
    $pager-form-line-height-em: $form-line-height * 1em !default;
    $pager-button-padding-y: .5em !default;
    $pager-buttons-size: calc(#{$pager-form-line-height-em} + 2px) !default; /// icon button size + 2px border (top and bottom)

    // Theme
    .k-pager-wrap {
        @include appearance( header );

        .k-pager-first {
            @include border-left-radius-only();
        }
        .k-pager-last {
            @include border-right-radius-only();
        }

        .k-link,
        .k-pager-nav {
            color: $pager-text;
            background-color: $pager-bg;
            border-color: $pager-border;
            border-width: 1px;
            border-style: solid;

            &:hover {
                color: $pager-hover-text;
                background-color: $pager-hover-bg;
                border-color: $pager-hover-border;
                z-index: 2;
            }

            &:focus {
                box-shadow: $pagination-focus-box-shadow;
                outline: 0;
                z-index: 2;
            }
        }

        .k-pager-refresh {
            @include border-radius( $border-radius );
        }

        .k-link,
        .k-state-selected {
            padding: $pager-button-padding-y;
            width: $pager-buttons-size;
            height: $pager-buttons-size;
            line-height: $pager-buttons-size;
        }

        .k-dropdown .k-dropdown-wrap {
            color: $body-color;
            background-color: $pager-bg;
            border-color: $pager-border;

            &.k-state-hover {
                background-color: $pager-hover-bg;
            }
        }
    }
    .k-pager-numbers {

        .k-state-selected,
        .k-state-selected:hover {
            border-width: 1px;
            border-style: solid;
            background-color: $pager-selected-bg;
            border-color: $pager-selected-border;
            z-index: 2;
            cursor: default;

            &.k-link {
                color: $pager-selected-text;
            }
        }

    }

    .k-link.k-pager-nav.k-state-disabled {}


    // Spacing
    .k-pager-nav + .k-pager-nav,
    .k-pager-nav + .k-pager-numbers,
    .k-pager-numbers + .k-pager-nav,
    .k-pager-numbers .k-current-page ~ li + li {
        margin-left: -1px;
    }

}