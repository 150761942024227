$widget-border-width: 1px !default;

@include exports( "common/layout" ) {

    // Layout
    .k-reset {
        margin: 0;
        padding: 0;
        border-width: 0;
        outline: 0;
        text-decoration: none;
        font: inherit;
        list-style: none;
    }


    // Layout
    .k-widget {
        border-width: $widget-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-size: $font-size;
        line-height: $line-height;
        display: block;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $rgba-transparent;
    }
    table.k-widget { display: table; }

    kendo-sortable {
        display: block;
    }


    // Overlay
    .k-overlay {
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .5;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
    }


    // RTL
    .k-rtl {
        direction: rtl;
    }


    // Links
    .k-link,
    .k-link:hover {
        color: inherit;
        text-decoration: none;
    }

    // Outline
    .k-content {
        outline: 0;
    }


    // Centering
    .k-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    // Off-screen container used during PDF export
    .k-pdf-export-shadow {
        position: absolute;
        overflow: hidden;
        left: -15000px;
        width: 14400px;
    }

    // PDF export icons fix
    .kendo-pdf-hide-pseudo-elements::before,
    .kendo-pdf-hide-pseudo-elements::after {
        // sass-lint:disable-block no-important
        display: none !important;
    }
}


@include exports( "common/theme" ) {

    // Widget
    .k-widget {
        @extend %text-selection !optional;
        @include appearance( widget );
    }


    // Panels
    .k-content {
        @include appearance( panel );
    }


    // Selected state
    .k-state-selected {
        color: $selected-text;
        background-color: $selected-bg;
        border-color: $selected-border;
    }

}


@include exports( "common/disabled" ) {

    // Disabled state
    .k-state-disabled,
    .k-widget[disabled] {
        @include disabled;


        .k-link,
        .k-button {
            cursor: default;
            outline: 0;
        }


        // Nested disabled
        [disabled],
        .k-state-disabled {
            opacity: 1;
            filter: grayscale(0);
        }
    }

}


@include exports( "common/misc/hr" ) {

    %hr {
        margin: $spacer-y auto;
        height: 0;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: $panel-border;
        display: block;
    }

    %hr-vertical {
        margin: 0;
        width: 0;
        height: auto;
        border-width: 0 0 0 1px;
        flex: 0 0 auto;
    }

    .k-hr {
        @extend %hr;
        padding: 0;
        float: none;
        clear: both;
    }

    // Horizontal rule
    .k-hbox > .k-hr {
        @extend %hr-vertical;
    }

    // Vertical rule
    .k-vbox > .k-hr {
        margin: 0;
        flex: 0 0 auto;
    }

    // Separator
    .k-separator {
        @extend %hr;
    }

}


@include exports( "common/misc/dirty" ) {

    // Dirty indicator
    .k-dirty {
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border-width: 3px;
        border-style: solid;
        border-color: $error $error transparent transparent;
        position: absolute;
        top: 0;
        right: 0;
    }

}
