﻿.grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.grid .cell .card {
    height: 100%;
}

.cell {
    max-width: 325px;
}

.card-grid-title {
    font-size: 1.3333rem;
    font-weight: 700;
    color: rgba(0,0,0,0.6);
    margin-bottom: 1rem;
}

.k-header.k-with-icon {
    &.k-filterable, &.k-sorted {
        & > .k-grid-filter {
            display: inline;
            margin-left: 0.5rem;
            position: unset;
        }

        & > .k-link {
            display: inline;
            margin: 0;
            padding: 0;
        }
    }
}
