﻿/// <reference path = "variables.less">
.checkbox {
    &, & > label {
        display: inline-flex;
        align-items: center;
    }

    & > label {
        padding-left: 0;
    }
}

// .checkbox label,
// .checkbox input.checkbox > [type="checkbox"]  {
//     margin: 0 3px;
// }

.nowrap.checkbox {
    white-space: nowrap;
}

.checkbox {
    margin: 5px 0;
    padding: 0;
}

.checkbox > [type="checkbox"],
label {
    margin-bottom: 0px !important;
    user-select: none;
}

.checkbox > [type="checkbox"] {
    position: absolute;
    left: -9999px;
    // opacity: .5;
}

.checkbox > [type="checkbox"] {
    // position: relative;
    // padding-left: 24px;
    // padding-right: 10px;
    // cursor: pointer;
    // display: flex;
    // align-items: flex-end;
    // height: 24px;
    &:checked + label svg {
        opacity: 1;
        transition: none;
    }

    &:not(:checked) + label svg {
        opacity: 0;
        transition: .2s ease opacity;
    }

    &:checked + label path {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset .35s cubic-bezier(1,.255,.395,.84);
    }

    & + label path {
        stroke-dasharray: 48px;
        stroke-dashoffset: 48px;
        transition: stroke-dashoffset 0s .3s;
    }

    &:checked + label .checkbox-box {
        background: $primary;
        border-color: mix(white, $primary, 30%);
    }

    & + label .checkbox-text {
        padding-left: 6px;
        padding-top: 4px;
    }

    &:disabled + label {
        cursor: not-allowed;

        & .checkbox-box {
            opacity: .3;
            cursor: not-allowed; // need to have this here also because 'cursor: pointer;' is manually set for this element
        }

        & .checkbox-text {
            opacity: .7;
        }
    }

    &:not(:checked) + label:hover .checkbox-box {
        border: 1px solid $primary;
    }

    &:focus {
        & + label .checkbox-box {
            border: 1px solid #007fff;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.13), 0 0 8px rgb(102, 175, 233);
        }
    }

    &:not(:checked) {
        &:focus, &:hover {
            & + .checkbox-box {
                background: mix(white, $primary, 97%);
            }
        }
    }
}

.checkbox > [type="checkbox"] + label .checkbox-box {
    border: 1px solid gray;
    background: #ffffff;
    border-radius: $border-radius-sm;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding-left: 0;
    position: relative;
    transition: border-color .3s;
    z-index: 2;
    transition: .2s ease background;
}


// inline checkboxes
.form-inline .checkbox {
    display: inline-flex;
    height: 34px;
    align-items: center;
}

.checkbox svg {
    height: 13px;
    width: 13px;
    top: 2px;
    left: 2px;
    position: absolute;
}

// .checkbox-label-text {
//     transform: translateY(-0.25em);
//     display: inline-block;
// }
