@include exports("dialog/theme") {

    .k-dialog {}


    // Buttons
    .k-dialog-buttongroup {
        border-color: $window-titlebar-border;
    }

}
