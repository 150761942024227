$switch-size: 6em !default;
$switch-handle-size: (.4 * $switch-size) !default;

@include exports('switch/layout') {
    $switch-border-width: 1px;
    $switch-padding: 2 * $switch-border-width;

    .k-switch {
        cursor: pointer;
        border: 0;
        display: inline-block;
        font-size: $font-size-xs;
        overflow: hidden;
        position: relative;
        text-align: left;
        user-select: none;
        vertical-align: middle;
        width: $switch-size;
        outline: 0;

        [type='checkbox'] {
            display: none;
        }
    }

    .k-switch,
    .k-switch-wrapper,
    .k-switch-container,
    .k-switch-handle {
        box-sizing: border-box;
    }

    .k-switch-wrapper {
        display: none; // TODO: do not render
    }

    .k-switch .k-switch-background {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .k-switch-container {
        padding: $switch-padding 0;
        display: block;
        width: 100%;
        background: transparent;
        transform: translateZ(0);
        outline: 0;
    }

    .k-switch-handle {
        position: relative;
        width: $switch-handle-size;
        height: $switch-handle-size;
        display: inline-block;
        margin: 0 ($switch-padding * 3) 0 $switch-padding;
        border-width: $switch-border-width;
        border-style: solid;
        vertical-align: middle;
    }

    .k-switch-label-off {
        left: ($switch-size / 2);
    }

    .k-switch-label-on {
        left: -$switch-handle-size;
    }

    .k-switch-label-on,
    .k-switch-label-off {
        top: -$switch-border-width;
        display: block;
        text-align: center;
        position: absolute;
        text-transform: uppercase;
        text-shadow: none;
        line-height: $switch-handle-size;
        vertical-align: middle;
    }

    .k-switch-container,
    .k-switch-wrapper {
        border-width: $switch-border-width;
        border-style: solid;
    }



    .k-switch[dir='rtl'] {
        .k-switch-label-off {
            left: -$switch-handle-size;
        }

        .k-switch-label-on {
            left: ($switch-size / 2);
        }
    }
}
