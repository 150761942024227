@include exports("splitter/theme") {

    $splitter-text: $button-text;
    $splitter-bg: darken($button-bg, 5) !default;
    $splitter-hovered-text: $button-hovered-text;
    $splitter-hovered-bg: darken($button-hovered-bg, 5) !default;

    // Splitbar
    .k-splitbar {
        color: $splitter-text;
        background-color: $splitter-bg;

        &:active,
        &.k-state-focused {
            color: $selected-text;
            background: $selected-bg;
        }
    }

    .k-splitbar-horizontal-hover,
    .k-splitbar-vertical-hover {
        color: $splitter-hovered-text;
        background-color: $splitter-hovered-bg;
    }

    .k-ghost-splitbar {
        @include appearance( hovered-button );
    }

}
