@include exports("dropdownlist/theme") {

    // Dropdown
    .k-dropdown {
        .k-dropdown-wrap {
            @include appearance( button );
            transition: $transition;
        }

        // Hover state
        > :hover,
        .k-state-hover {
            @include appearance( hovered-button );
        }

        // Focused state
        .k-state-focused {
            box-shadow: $button-focused-shadow;
        }

        // Active state
        .k-state-active {
            @include appearance( pressed-button );
        }

    }

}
