@include exports('action-buttons/layout') {

    .k-action-buttons {
        border-width: 1px 0 0;
        border-style: solid;
        border-color: inherit;
        display: flex;
        flex-direction: row;
        clear: both;

        .k-button {
            @include border-radius( 0 );
            padding: ($window-inner-padding-x * .75) $window-inner-padding-x;
            border-width: 0;
            border-color: inherit;
            color: inherit;
            background: none;
            flex: 1 1 auto;
        }
        .k-button + .k-button {
            border-left-width: 1px;
        }

        .k-rtl &,
        [dir="rtl"] & {
            .k-button + .k-button {
                border-left-width: 0;
                border-right-width: 1px;
                border-right-style: solid;
                margin-left: 0;
            }
        }
    }

}
