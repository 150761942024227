@include exports( "common/resizing" ) {

    // Resize handle
    .k-resize-handle,
    .k-resize-hint {
        position: absolute;
        border-color: inherit;
    }
    .k-resize-handle {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .k-resize-handle::before {
        content: "";
        border: 0 solid;
        border-color: inherit;
    }
    .k-resize-n {
        width: 100%;
        height: 6px;
        flex-direction: row;
        left: 0;
        top: -3px;
        cursor: n-resize;
    }
    .k-resize-s {
        width: 100%;
        height: 6px;
        flex-direction: row;
        left: 0;
        bottom: -3px;
        cursor: s-resize;
    }
    .k-resize-w {
        width: 6px;
        height: 100%;
        flex-direction: col;
        top: 0;
        left: -3px;
        cursor: w-resize;
    }
    .k-resize-e {
        width: 6px;
        height: 100%;
        flex-direction: col;
        top: 0;
        right: -3px;
        cursor: w-resize;
    }

    .k-resize-sw,
    .k-resize-se,
    .k-resize-nw,
    .k-resize-ne {
        width: 5px;
        height: 5px;
    }

    .k-resize-sw {
        cursor: sw-resize;
        bottom: 0;
        left: 0;
    }
    .k-resize-se {
        cursor: se-resize;
        bottom: 0;
        right: 0;
    }
    .k-resize-nw {
        cursor: nw-resize;
        top: 0;
        left: 0;
    }
    .k-resize-ne {
        cursor: ne-resize;
        top: 0;
        right: 0;
    }

}
